import { useState, useEffect } from 'react';

const CookieBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!document.cookie.includes('cookieConsent=true')) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    document.cookie = `cookieConsent=true; max-age=${
      60 * 60 * 24 * 365 * 5
    }; path=/`;
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className='fixed bottom-0 left-0 right-0 bg-gray-600 text-white p-4 flex justify-between items-center z-50'>
      <span>
        Ta strona korzysta z ciasteczek. Dalsze korzystanie ze strony oznacza,
        że zgadzasz się na ich użycie.
      </span>
      <button
        onClick={handleAccept}
        className='bg-orange-500 text-white py-2 px-4 rounded'
      >
        Zgoda
      </button>
    </div>
  );
};

export default CookieBanner;
