import { useContext } from 'react';
import { CartContext } from '@/context/CartCtx';
import { CartContextProps } from '@/types/types';

export const useCart = (): CartContextProps => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
};
