import { Url } from '../../types/types';

export const url: Url[] = [
  {
    url: 'https://www.youtube.com/watch?v=6VElWbND-zg&list=PLZPZq0r_RZOOkUQbat8LyQii36cJf2SWT',
    title: 'Kurs Linuxa',
  },
  {
    url: 'https://www.youtube.com/watch?v=EReeJSaU0Og',
    title: 'Kurs Linuxa',
  },
  {
    url: 'https://www.youtube.com/watch?v=VL_g3LjsFqs',
    title: 'Kurs Linuxa',
  },
  {
    url: 'https://www.youtube.com/watch?v=V_NXT2-QIlE',
    title: 'Kurs Linuxa',
  },
  {
    url: 'https://www.youtube.com/watch?v=8A0E1dSyjFM',
    title: 'Kurs Linuxa',
  },
  {
    url: 'https://www.youtube.com/watch?v=LKFrQXaoSMQ',
    title: 'Kurs Linuxa',
  },
];
