
export type RegistrationState = {
  status: 'loading' | 'success' | 'error' | 'alreadyConfirmed' | 'invalidCode';
  message: string;
};

export type RegistrationAction =
  | { type: 'CONFIRM_REQUEST' }
  | { type: 'CONFIRM_SUCCESS' }
  | { type: 'ALREADY_CONFIRMED' }
  | { type: 'INVALID_CODE' }
  | { type: 'CONFIRM_ERROR' };

const registrationReducer = (
  state: RegistrationState,
  action: RegistrationAction
): RegistrationState => {
  switch (action.type) {
    case 'CONFIRM_REQUEST':
      return { status: 'loading', message: 'Loading...' };
    case 'CONFIRM_SUCCESS':
      return {
        status: 'success',
        message: 'Twoje konto zostało potwierdzone. Dziękujemy!',
      };
    case 'ALREADY_CONFIRMED':
      return {
        status: 'alreadyConfirmed',
        message: 'Twoje konto zostało już potwierdzone.',
      };
    case 'INVALID_CODE':
      return { status: 'invalidCode', message: 'Kod potwierdzający jest błędny.' };
    case 'CONFIRM_ERROR':
      return {
        status: 'error',
        message: 'Wystąpił problem, spróbuj ponownie później.',
      };
    default:
      return state;
  }
};

export default registrationReducer;
