import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear();
  const socialIcons = [
    {
      path: '/facebook2.svg',
      name: 'Facebook',
      url: 'https://www.facebook.com',
    },
    {
      path: '/instagram.svg',
      name: 'Instagram',
      url: 'https://www.instagram.com',
    },
    { path: '/tiktok.svg', name: 'TikTok', url: 'https://www.tiktok.com' },
    { path: '/youtube.svg', name: 'YouTube', url: 'https://www.youtube.com' },
  ];

  return (
    <footer className='flex flex-col items-center justify-center bg-slate-50 p-4 text-zinc-600 text-xs mt-auto'>
      <hr className='w-full border-t-2 border-gray-300 my-4' />
      <div className='w-full flex flex-col md:flex-row justify-between items-center font-semibold transition-all duration-300 ease-in-out'>
        <div className='flex flex-col items-start mb-4 md:mb-0'>
          <img src='/logo.jpg' alt='Logo' className='h-16 w-16 mb-2 md:block hidden' />
          <p className='mx-2'>© {currentYear} Kamil Pfaff Konsulting IT</p>
        </div>
        <div className='flex flex-col items-center mb-4 md:mb-0'>
          <a
            href='mailto:kontakt@pfaff.app'
            className='mb-2 hover:text-orange-500'
          >
            Skontaktuj się z nami
          </a>
          <Link to='/privacy-policy' className='mb-2 hover:text-orange-500' onClick={() => window.scrollTo({top: 0, behavior: 'smooth'})}>
            Polityka prywatności
          </Link>
        </div>
        <div className='flex space-x-4 transition-all duration-300 ease-in-out'>
          {socialIcons.map((icon, index) => (
            <a
              href={icon.url}
              key={index}
              className='hover:rotate-12 hover:opacity-70'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={icon.path} alt={icon.name} className='h-6 w-6' />
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
