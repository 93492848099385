import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { LoginFormInput } from '@/types/types';

const Auth = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormInput>();

  const handleConfirm: SubmitHandler<LoginFormInput> = async ({ code }) => {
    try {
      // await fetch('/api/auth', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify({ code }),
      // });
      navigate('/login');
    } catch (error) {
      console.error('Code confirmation failed:', error);
      console.log(code);
    }
  };

  return (
    <div className='flex flex-col sm:flex-row justify-center items-center h-screen'>
      <div className='flex justify-center items-center w-2/5'>
        <img src='/auth.webp' alt='Confirm Code painting' />
      </div>
      <div className='flex flex-col items-center p-6 shadow-md rounded-md bg-white mx-12'>
        <h2 className='text-2xl mb-4 text-gray-700 text-center w-2/3'>
          Weryfikacja e-mail
        </h2>
        <form onSubmit={handleSubmit(handleConfirm)} className='w-full'>
          <div className='mb-4'>
            <label
              htmlFor='code'
              className='block text-sm font-medium text-gray-700 mb-1'
            >
              Kod potwierdzający
            </label>
            <input
              type='text'
              id='code'
              {...register('code', { required: 'Wprowadź kod' })}
              autoFocus
              className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500'
            />
            {errors.code && (
              <span className='text-red-500 text-sm'>
                {errors.code.message}
              </span>
            )}
          </div>
          <button
            type='submit'
            className='w-full px-4 py-2 bg-orange-500 text-white font-medium rounded-md shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500'
          >
            Potwierdź
          </button>
        </form>
      </div>
    </div>
  );
};

export default Auth;
