import { Link } from 'react-router-dom';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className='container mx-auto px-20 py-8 leading-relaxed text-zinc-700'>
      <h1 className='text-2xl font-bold mb-4 uppercase'>
        Polityka Prywatności i Polityka Plików Cookie
      </h1>

      <h2 className='text-xl font-semibold mt-6'>I. Postanowienia ogólne</h2>
      <ol className='list-decimal list-inside ml-4'>
        <li>
          Niniejsza Polityka ochrony prywatności określa sposób zbierania,
          przetwarzania i przechowywania danych osobowych koniecznych do
          świadczenia usług drogą elektroniczną za pośrednictwem serwisu
          internetowego w domenie xyz.pl (dalej: Serwis).
        </li>
        <li>
          Administratorem danych osobowych Użytkowników jest Kamil Pfaff
          Konsulting IT, z siedzibą w Pszczynie przy ul. Kasztelańskiej 4,
          43-200 Pszczyna (dalej: Administrator).
        </li>
        <li>
          Dane osobowe przetwarzane są zgodnie z Rozporządzeniem Parlamentu
          Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w
          sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
          osobowych i w sprawie swobodnego przepływu takich danych oraz
          uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych)
          (dalej: RODO).
        </li>
        <li>
          Zbierane przez Administratora dane będą:
          <ol className='list-[lower-alpha] list-inside ml-4'>
            <li>przetwarzane zgodnie z prawem,</li>
            <li>
              przetwarzane w wyraźnie określonych celach i niepoddawane dalszemu
              przetwarzaniu niezgodnemu z tymi celami,
            </li>
            <li>
              merytorycznie poprawne i adekwatne w stosunku do celów, w jakich
              są przetwarzane,
            </li>
            <li>
              przechowywane nie dłużej niż jest to niezbędne do osiągnięcia celu
              przetwarzania.
            </li>
          </ol>
        </li>
      </ol>

      <h2 className='text-xl font-semibold mt-6'>
        II. Zakres zbieranych danych
      </h2>
      <ol className='list-decimal list-inside ml-4'>
        <li>
          Dane osobowe, które przetwarza Administrator to przede wszystkim: imię
          i nazwisko, adres e-mail, dane niezbędne do wystawienia faktury oraz
          dane zawarte w treści przesłanej do nas wiadomości, które otrzymujemy
          od Państwa w skutek Państwa dobrowolnej zgody.
        </li>
        <li>
          Państwa dane osobowe są gromadzone i przetwarzane jedynie w oparciu o
          odpowiednie podstawy prawne, a ich zakres zależy od rodzaju
          świadczonej usługi.
        </li>
      </ol>

      <h2 className='text-xl font-semibold mt-6'>
        III. Cel i podstawy prawne zbierania danych
      </h2>
      <ol className='list-decimal list-inside ml-4'>
        <li>
          Administrator przetwarza dane osobowe niezbędne do świadczenia i
          rozwoju oferowanych usług dostępnych za pośrednictwem Serwisu oraz
          poszczególnych jego funkcjonalności.
        </li>
        <li>
          Dane osobowe przetwarzane będą w następujących celach:
          <ol className='list-[lower-alpha] list-inside ml-4'>
            <li>
              rejestracji konta, weryfikacji tożsamości Użytkownika oraz
              realizacji umowy o świadczeniu usług drogą elektroniczną zgodnie z
              ustawą z dnia 18 lipca 2002 r. o świadczeniu usług drogą
              elektroniczną, w tym w szczególności poprzez zapewnienie
              możliwości korzystania z konta Użytkownika – na podstawie
              akceptacji warunków Regulaminu (art. 6 ust. 1 lit. b RODO);
            </li>
            <li>
              promowania przez Administratora produktów i/lub usług własnych
              oraz swoich Partnerów poprzez kierowanie drogą elektroniczną
              Informacji marketingowych (newsletter) o ile Użytkownik wyraził
              zgodę na otrzymywanie takich powiadomień za pośrednictwem e-mail
              (art. 6 ust. 1 lit. a RODO);
            </li>
            <li>
              w celach analitycznych i statystycznych na podstawie prawnie
              uzasadnionego interesu Administratora polegającego na prowadzeniu
              weryfikacji aktywności Użytkowników i ich preferencji dla
              optymalizacji usług i produktów oraz stosowanych funkcjonalności
              Serwisu (art. 6 ust. 1 lit. f RODO);
            </li>
            <li>
              ewentualnego ustalenia, dochodzenia roszczeń lub obrony przed nimi
              na podstawie prawnie uzasadnionego interesu Administratora
              polegającego na ochronie jej praw (art. 6 ust. 1 lit. f RODO).
            </li>
          </ol>
        </li>
        <li>
          W każdym z wymienionych powyżej przypadków (ust 2) podanie danych jest
          dobrowolne, jednak konieczne do zawarcia umowy lub skorzystania z
          innych funkcjonalności Serwisu.
        </li>
      </ol>

      <h2 className='text-xl font-semibold mt-6'>
        IV. Okres przetwarzania danych osobowych
      </h2>
      <ol className='list-decimal list-inside ml-4'>
        <li>
          Dane osobowe będą przetwarzane przez okres, w którym osoba pozostaje
          aktywnym Użytkownikiem Serwisu (posiada konto Użytkownika), a po tym
          czasie przez okres niezbędny do zachowania zgodności z przepisami
          prawa, dochodzenia lub obrony przed ewentualnymi roszczeniami, jednak
          nie dłużej niż 3 lat liczone od dnia rozwiązania umowy o świadczeniu
          usług drogą elektroniczną.
        </li>
        <li>
          Dane przetwarzane na podstawie zgody będą przetwarzane do czasu
          wycofania udzielonej zgody z zastrzeżeniem, że wycofanie tej zgody nie
          ma wpływu na zgodność przetwarzania danych, którego dokonano przed tym
          wycofaniem.
        </li>
        <li>
          W przypadku czytelnego dla nas zamknięcia rozmów z Państwa strony dane
          zostaną usunięte niezwłocznie z roboczych baz systemowych, a z kopii
          bezpieczeństwa przed upływem 30 dni.
        </li>
      </ol>

      <h2 className='text-xl font-semibold mt-6'>
        V. Informacje o przetwarzaniu
      </h2>
      <ol className='list-decimal list-inside ml-4'>
        <li>
          Dane osobowe w zależności od celu przetwarzania mogą zostać ujawnione:
          <ol className='list-[lower-alpha] list-inside ml-4'>
            <li>podmiotom powiązanym z Administratorem,</li>
            <li>podmiotom współpracującym z Administratorem,</li>
            <li>
              podwykonawcom, w szczególności podmiotom dostarczającym i
              obsługującym wybrane systemy i rozwiązania informatyczne,
            </li>
            <li>podmiotom obsługującym płatności internetowe,</li>
            <li>kancelariom prawnym.</li>
          </ol>
        </li>
        <li>
          Dane osobowe przetwarzane przez Administratora nie będą przekazywane
          poza Europejski Obszar Gospodarczy ani do organizacji
          międzynarodowych.
        </li>
      </ol>

      <h2 className='text-xl font-semibold mt-6'>
        VI. Prawa osób, których dane dotyczą
      </h2>
      <ol className='list-decimal list-inside ml-4'>
        <li>
          Użytkownik serwisu ma prawo do:
          <ol className='list-[lower-alpha] list-inside ml-4'>
            <li>dostępu do treści swoich danych osobowych,</li>
            <li>sprostowania danych,</li>
            <li>usunięcia danych,</li>
            <li>ograniczenia przetwarzania danych,</li>
            <li>
              wniesienia sprzeciwu wobec przetwarzania swoich danych osobowych,
            </li>
            <li>
              wycofania zgody na przetwarzanie danych w dowolnym momencie.
            </li>
          </ol>
        </li>
        <li>
          Aby skorzystać z przysługujących Państwu praw zachęcamy do
          bezpośredniego kontaktu z Administratorem za pośrednictwem adresu
          e-mail: <a href='mailto:kontakt@pfaff.app'>kontakt@pfaff.app</a>.
        </li>
        <li>
          W sytuacji chęci wycofania zgody sugerujemy w tym celu przesłać
          wiadomość z tego samego adresu e-mail lub numeru telefonu, na który
          otrzymujecie Państwo treści marketingowe. Wyłączną konsekwencją
          cofnięcia zgody będzie fakt, że nie będziecie Państwo mogli dłużej
          otrzymywać od nas informacji o naszej ofercie i innych treści
          marketingowych. Cofnięcie zgody nie wpływa na legalność przetwarzania
          przez nas danych, które dokonało się przed tym cofnięciem.
        </li>
        <li>
          Użytkownik ma prawo do wniesienia skargi do Prezesa Urzędu Ochrony
          Danych Osobowych w sytuacji, w której uzna, że przetwarzanie narusza
          jego prawa i wolności.
        </li>
        <li>
          W procesie przetwarzania danych nie dochodzi do zautomatyzowanego
          podejmowania decyzji, w tym do profilowania.
        </li>
      </ol>

      <h2 className='text-xl font-semibold mt-6'>VII. Postanowienia końcowe</h2>
      <ol className='list-decimal list-inside ml-4'>
        <li>
          Administrator zastrzega sobie prawo do wprowadzenia zmian w niniejszej
          Polityce prywatności jednocześnie zapewniając, że prawa Użytkowników
          wynikające z niniejszego dokumentu nie zostaną ograniczone.
        </li>
        <li>
          O wszelkich zmianach w Polityce prywatności Użytkownik zostanie
          poinformowany poprzez komunikat dostępny w Serwisie.
        </li>
      </ol>

      <p className='mt-4'>
        Więcej informacji na temat usunięcia danych osobowych znajdziesz{' '}
        <Link
          to='/data-deletion-instructions'
          className='text-orange-500 underline'
        >
          tutaj
        </Link>
        .
      </p>

      <h2 className='text-xl font-semibold mt-6'>PLIKI COOKIE</h2>

      <h3 className='text-lg font-semibold mt-4'>I. Informacje ogólne</h3>
      <p>
        Serwis internetowy korzysta z informacji zapisywanych za pomocą plików
        „Cookie”, czyli danych informatycznych, przechowywanych w urządzeniach
        końcowych użytkowników przeznaczonych do korzystania ze stron
        internetowych. Pliki te pozwalają rozpoznać urządzenie użytkownika i
        odpowiednio wyświetlać stronę internetową dostosowaną do indywidualnych
        preferencji. Zazwyczaj zawierają one nazwę strony internetowej, z której
        pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny
        numer.
      </p>

      <h3 className='text-lg font-semibold mt-4'>
        II. Rodzaje wykorzystywanych plików Cookie
      </h3>
      <ol className='list-decimal list-inside ml-4'>
        <li>
          Serwis wykorzystuje następujące pliki Cookie:
          <ol className='list-[lower-alpha] list-inside ml-4'>
            <li>
              <strong>niezbędne:</strong> konieczne do prawidłowego
              funkcjonowania serwisu – pliki przetwarzane w oparciu o
              usprawiedliwiony interes administratora (art. 6 ust. 1 lit. f
              RODO);
            </li>
            <li>
              <strong>statystyczne:</strong> pozwalają one badać ruch na stronie
              internetowej, poznawać preferencje naszych użytkowników,
              analizować ich zachowanie w serwisie oraz umożliwiają interakcje z
              zewnętrznymi sieciami i platformami – pliki przetwarzane w oparciu
              o dobrowolną zgodę użytkownika (art. 6 ust. 1 lit. a RODO);
            </li>
            <li>
              <strong>marketingowe:</strong> pozwalają na dostosowanie
              wyświetlanych reklam i treści do preferencji naszych użytkowników
              oraz prowadzić spersonalizowane kampanie marketingowe – pliki
              przetwarzane w oparciu o dobrowolną zgodę użytkownika (art. 6 ust.
              1 lit. a RODO).
            </li>
          </ol>
        </li>
      </ol>

      <h3 className='text-lg font-semibold mt-4'>
        III. Czas przechowywania plików Cookie
      </h3>
      <ol className='list-decimal list-inside ml-4'>
        <li>
          Pliki Cookie wykorzystywane przez serwis przechowywane są zgodnie z
          poniższymi informacjami:
          <ol className='list-[lower-alpha] list-inside ml-4'>
            <li>
              <strong>niezbędne:</strong> są to pliki stałe, sesyjne (usuwane po
              zamknięciu okna) oraz przechowywane przez 30 dni;
            </li>
            <li>
              <strong>statystyczne:</strong> są to pliki sesyjne (usuwane po
              zamknięciu okna) oraz przechowywane od 1 dnia do 2 lat;
            </li>
            <li>
              <strong>marketingowe:</strong> są to pliki sesyjne (usuwane po
              zamknięciu okna) oraz przechowywane od 1 dnia do 1 roku.
            </li>
          </ol>
        </li>
      </ol>

      <h3 className='text-lg font-semibold mt-4'>IV. Informacje szczegółowe</h3>
      <ol className='list-decimal list-inside ml-4'>
        <li>
          Standardowo większość przeglądarek internetowych dostępnych na rynku
          domyślnie akceptuje zapisywanie plików Cookie.
        </li>
        <li>
          Użytkownik ma możliwość określenia warunków korzystania z plików
          Cookie:
          <ol className='list-[lower-alpha] list-inside ml-4'>
            <li>
              za pomocą ustawień własnej przeglądarki internetowej - oznacza to,
              że może np. częściowo ograniczyć (np. czasowo) lub całkowicie
              wyłączyć możliwość zapisywania plików Cookie na swoim urządzeniu –
              w tym ostatnim wypadku jednak może to mieć wpływ na niektóre
              funkcjonalności Serwisu;
            </li>
          </ol>
        </li>
        <li>
          Użytkownik może w każdej chwili usunąć pliki Cookie korzystając z
          dostępnych funkcji w przeglądarce internetowej, której używa.
        </li>
        <li>
          Szczegółowe informacje na temat zmiany ustawień dotyczących plików
          Cookie oraz ich samodzielnego usuwania w najpopularniejszych
          przeglądarkach internetowych dostępne są w dziale pomocy przeglądarki
          internetowej.
        </li>
      </ol>
    </div>
  );
};

export default PrivacyPolicy;
