import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion"

export default function FAQ() {
  const faqs = [
    {
      question: 'Dla kogo są nasze kursy?',
      answer:
        [
          'Dla każdego, kto pragnie rozwijać się w kierunku \'devops\', administracji, czy programowania - jednocześnie nabywając umiejętności poprawnej konfiguracji infrastruktury dla swoich aplikacji.', '',
          'Nasze kursy są skierowane zarówno do początkujących, jak i bardziej zaawansowanych użytkowników, co nie zmienia faktu, że są to kursy mocno techniczne, nakierowane na poznanie konkretnych technologii i praktycznych zagadnień z nimi związanych. ',
          'Mając wcześniejsze doświadczenie z podstawami programowania czy administracji systemami, z pewnością łatwiej będzie Ci zrozumieć zagadnienia poruszane w kursach.'
        ],
    },
    {
      question: 'Czy muszę mieć wcześniejsze doświadczenie, aby zacząć?',
      answer:
        [
          'Nie, nie musisz!',
          'Nasze kursy zaprojektowane są jako ścieżka, dzięki której możesz pogłębiać swoje umiejętności.', '',
          'Standardowa (i przez nas rekomendowana) ścieżka nauki to Linux => Ansible/Docker (dowolna kolejność) => Kubernetes.', '',
          'Takie podejście pozwoli Ci zrozumieć podstawy Linuksa, które są niezbędne do pełnego wykorzystania Ansible\'a czy Dockera.',
          'Jeżeli jednak czujesz się na siłach, możesz zacząć od dowolnego kursu, który Cię interesuje.',
          'Dla ułatwienia Ci takiego podejścia - w każdym kursie znajdziesz powtórzenie materiałów z poprzednich kursów, które są niezbędne do zrozumienia nowych zagadnień.',
        ],
    },
    {
      question: 'Czy mogę wrócić do materiałów po ukończeniu kursu?',
      answer:
        [
          'Jasne! Kurs kupujesz raz, a dostęp do niego masz już zawsze.',
          'Możesz wracać do materiałów, ponawiać tematy - kiedy tylko potrzebujesz.', '',
          'Limity wykorzystania dotyczą jedynie środowisk ćwiczeniowych. Ale spokojnie - są one wysokie, i na spokojnie wystarczą Ci na zrealizowanie wszystkich ćwiczeń z kursu.' +
          'Poza tym - limity te dotyczą wyłącznie czasu przez które środowiska są uruchomione, a nie terminu ich wykorzystania. Mają na celu wyłącznie eliminację nadużyć.'
        ],
    },
    {
      question: 'Jak mogę opłacić kurs?',
      answer:
        [
          'Kurs możesz zakupić na stronie, na której obecnie się znajdujesz. Płatności obsługiwane są przez platformę Przelewy24, która umożliwia płatność najpopularniejszymi obecnie metodami.', '',
          'Jeżeli chcesz otrzymać proformę i dokonać przelewu z konta firmowego - skontaktuj się z nami mailowo - poprzez adres kontakt@pfaff.app',
          'Przy większych zamówieniach możemy zaoferować rabaty ;)'
        ],
    },
    {
      question: 'Na jaki okres czasu zyskuję dostęp do kursu?',
      answer:
        ['Dostęp do kursu uzyskujesz na zawsze - nie ma limitu czasowego.'],
    },
    {
      question: 'Czy otrzymam fakturę VAT?',
      answer:
        [
          'Oczywiście. Podczas składania zamówienia zaznacz opcję zakupu na firmę i podaj numer NIP oraz dane do faktury.', '',
          'Jeżeli chcesz otrzymać proformę i dokonać przelewu z konta firmowego - skontaktuj się z nami mailowo - poprzez adres kontakt@pfaff.app',
          'Przy większych zamówieniach możemy zaoferować rabaty ;)'
        ],
    },
    {
      question: 'Co zrobić jeżeli napotkam problemy techniczne podczas kursu?',
      answer:
        [
          'Skontaktuj się z nami mailowo - poprzez adres kontakt@pfaff.app', '',
          'Opisz tam swój problem najdokładniej jak potrafisz. Im więcej informacji dostaniemy, tym szybciej będziemy mogli Ci pomóc.',
        ],
    },
  ];

  return (
    <main className='flex flex-col justify-center items-center px-2 py-6'>
      <div className='flex flex-col items-center justify-center text-center py-1'>
        <h3 className='text-lg font-bold uppercase mb-[-2em]'>
          najczęściej zadawane
        </h3>
        <p className='text-3xl font-bold mb-4 pt-7'>Pytania i odpowiedzi</p>
      </div>

      <Accordion type="single" collapsible className='w-full mx-2 px-4'>
      {faqs.map((faq, index) => (
  <AccordionItem key={index} value={`${index}`} className='w-full'>
    <AccordionTrigger className='text-lg'>{faq.question}</AccordionTrigger>
    <AccordionContent>
      <p className='pb-4'>
        {faq.answer.map((answer, answerIndex) => (
            <span key={answerIndex}>{answer}<br/></span>
        ))}
      </p>
    </AccordionContent>
  </AccordionItem>
))}

      </Accordion>
    </main>
  );
}
