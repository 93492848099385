import { createBrowserRouter } from 'react-router-dom';

import Home from '@/pages/Home';
import Lesson from '@/components/lessons/Lesson';
import Layout from '@/layout/Layout';
import Auth from '@/auth/Auth';
import Courses from '@/components/courses/Courses';
import { LessonProvider } from '@/context/LessonProvider';
import ShoppingPage from '@/components/courses/ShoppingPage';
import NewsletterResignationPage from '@/pages/NewsletterResignationPage';
import NewsletterWithdrawResignationPage from '@/pages/NewsletterWithdrawResignationPage';
import NewsletterConfirmationPage from '@/pages/NewsletterConfirmationPage';
import RegistrationConfirmationPage from '@/pages/RegistrationConfirmationPage';
import NotFound from '@/pages/404';
import OAuthRedirectHandler from '@/auth/OAuthRedirectHandler';
import PrivacyPolicy from '@/pages/PrivacyPolicy';
import DataDeletionInstructions from '@/pages/DataDeletionInstructions';
import ChangePasswordPage from '@/pages/ChangePasswordPage';
import UserActionsPage from '@/pages/UserActionsPage';
import { ToastProvider } from '@/components/ui/toast';
import CartPage from '@/pages/CartPage';
import CheckoutForm from '@/pages/CheckoutForm';
import OrderSummary from '@/pages/OrderSummaryPage';

export const router = createBrowserRouter([
  {
    path: '*',
    element: (
      <ToastProvider>
        <Layout>
          <NotFound />
        </Layout>
      </ToastProvider>
    ),
  },
  {
    path: '/',
    element: (
      <ToastProvider>
        <Layout>
          <LessonProvider>
            <Home />
          </LessonProvider>
        </Layout>
      </ToastProvider>
    ),
  },
  {
    path: '/oauth-confirm',
    element: (
      <Layout>
        <LessonProvider>
          <ToastProvider>
            <OAuthRedirectHandler />
          </ToastProvider>
        </LessonProvider>
      </Layout>
    ),
  },
  {
    path: '/courses',
    element: (
      <Layout>
        <LessonProvider>
          <Courses />
        </LessonProvider>
      </Layout>
    ),
  },
  {
    path: '/courses/course-details/:courseId',
    element: (
      <Layout>
        <ShoppingPage />
      </Layout>
    ),
  },
  {
    path: '/cart',
    element: (
      <Layout>
        <CartPage />
      </Layout>
    ),
  },
  {
    path: '/cart/checkout',
    element: (
      <Layout>
        <CheckoutForm />
      </Layout>
    ),
  },
  {
    path: '/cart/order-summary',
    element: (
      <Layout>
        <OrderSummary />
      </Layout>
    ),
  },
  {
    path: '/courses/:courseId/lesson/:lessonId',
    element: (
      <Layout>
        <Lesson />
      </Layout>
    ),
  },
  {
    path: '/auth',
    element: (
      <Layout>
        <Auth />
      </Layout>
    ),
  },
  {
    path: '/user-actions/*',
    element: (
      <Layout>
        <UserActionsPage />
      </Layout>
    ),
    children: [
      {
        path: 'login',
        element: <UserActionsPage />,
      },
      {
        path: 'register',
        element: <UserActionsPage />,
      },
      {
        path: 'resend-email',
        element: <UserActionsPage />,
      },
      {
        path: 'email-notification',
        element: <UserActionsPage />,
      },
      {
        path: 'reset-password',
        element: <UserActionsPage />,
      },
    ],
  },
  {
    path: '/reset-password',
    element: (
      <Layout>
        <ChangePasswordPage />
      </Layout>
    ),
  },
  {
    path: '/newsletter-resignation',
    element: (
      <Layout>
        <NewsletterResignationPage />
      </Layout>
    ),
  },
  {
    path: '/newsletter-confirmation',
    element: (
      <Layout>
        <NewsletterConfirmationPage />
      </Layout>
    ),
  },
  {
    path: '/newsletter-enable',
    element: (
      <Layout>
        <NewsletterWithdrawResignationPage />
      </Layout>
    ),
  },
  {
    path: '/registration-confirmation',
    element: (
      <Layout>
        <RegistrationConfirmationPage />
      </Layout>
    ),
  },
  {
    path: '/privacy-policy',
    element: (
      <Layout>
        <PrivacyPolicy />
      </Layout>
    ),
  },
  {
    path: '/data-deletion-instructions',
    element: (
      <Layout>
        <DataDeletionInstructions />
      </Layout>
    ),
  },
]);
DataDeletionInstructions;
