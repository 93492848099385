import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const Loading = () => {
  return (
    <div className='h-screen flex justify-center items-center'>
      <Stack className='text-gray-500'>
        <CircularProgress color='inherit' />
      </Stack>
    </div>
  );
};

export default Loading;
