import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import AXIOS from '@/util/axios';
import { NewsletterFormData } from '@/types/types';
import { Modal } from './Modal';

export default function Newsletter() {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [confirmationSent, setConfirmationSent] = useState(false);

  const mutation = useMutation<void, Error, NewsletterFormData>({
    mutationFn: async (formData: NewsletterFormData) => {
      await AXIOS.post('/api/newsletter/newsletter-recipients', formData);
    },
    onSuccess: () => {
      document.cookie = `newsletter_signed_up=true;max-age=${
        60 * 60 * 24 * 365
      }`;
      setEmail('');
      setName('');
      setErrorMessage('');
      setConfirmationSent(true);
      setShowModal(true);
    },
    onError: (err: Error) => {
      if (
        err.message.includes(
          'Confirmation email already sent within the last 30 minutes'
        )
      ) {
        setErrorMessage(
          'E-mail potwierdzający został już wysłany w ciągu ostatnich 30 minut.'
        );
      } else {
        setErrorMessage('Wystąpił błąd. Spróbuj ponownie później.');
      }
      console.error(err);
    },
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setErrorMessage('');
    mutation.mutate({ email, name });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <main className='w-fit relative '>
      <div className="custom-shape-divider-top-1728254425">
        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
          <path
            d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
            opacity=".25" className="shape-fill"></path>
          <path
            d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
            opacity=".5" className="shape-fill"></path>
          <path
            d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
            className="shape-fill"></path>
        </svg>
      </div>

      <div
        className='flex flex-col md:flex-row md:justify-center md:items-center px-4 py-32 bg-[url("/headerbg.webp")] bg-cover bg-center w-screen'>
        <div
          className='max-w-1/3 md:w-screen flex flex-col justify-between items-center order-2 md:order-1 text-center'>
          <h2 className='w-screen text-3xl font-semibold mb-6 px-2.5'>
            Otrzymaj dostęp do{' '}
            <strong className='text-[#ff7a4e]'>darmowych</strong> materiałów
          </h2>
          <h3 className='text-center text-lg w-screen mb-6 px-4'>
            {confirmationSent
              ? 'Dziękujemy za zapisanie się do newslettera!'
              : 'Zapisz się do newslettera i skorzystaj z naszych darmowych treści!'}
          </h3>

          {!mutation.isSuccess && (
            <form onSubmit={handleSubmit}>
              <div className='flex flex-col justify-center items-center mb-4 w-screen px-4'>
                <input
                  type='email'
                  id='email'
                  placeholder='Wpisz swój adres e-mail'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className='mt-1 p-2 w-full sm:w-1/2 border border-gray-300 bg-gray-100 rounded-lg mb-4'
                  required
                />
                <button
                  type='submit'
                  className='mt-4 px-4 py-3 bg-[#ff7a4e] text-white p-1 hover:bg-[#e4663c] rounded-lg'
                  disabled={mutation.isPending}
                >
                  Zapisuję się
                </button>
              </div>

              {errorMessage && (
                <div className='text-center text-red-700 p-2 mb-4 rounded'>
                  {errorMessage}
                </div>
              )}
              {mutation.isSuccess && showModal && (
                <Modal
                  message='Dziękujemy za zapisanie się do newslettera!'
                  onClose={handleCloseModal}
                />
              )}
            </form>
          )}

          {confirmationSent && (
            <div className='text-center p-4 mt-6'>
              Pamiętaj, aby sprawdzić skrzynkę pocztową i potwierdzić adres
              email 🙂
            </div>
          )}
        </div>
      </div>
    </main>
  );
}
