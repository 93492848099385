import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import AXIOS from '@/util/axios';
import { useAuth } from '@/hooks/useAuth';
import { useToast } from '@/components/ui/use-toast';

const OAuthRedirectHandler = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { setAuthState } = useAuth();
  const { toast } = useToast();

  useEffect(() => {
    const refreshToken = searchParams.get('refreshToken');
    const error = searchParams.get('error');
    const loginType = searchParams.get('login_type');

    if (error === 'oauth_response_error') {
      toast({
        title: 'Authentication Error',
        description: 'Authentication was aborted. Please try again.',
        variant: 'destructive',
      });
      navigate('/user-actions/login');
    } else if (error === 'wrong_login_method') {
      const loginMethod =
        loginType === 'email'
          ? 'Email'
          : loginType === 'oauth_google'
          ? 'Google'
          : loginType === 'oauth_facebook'
          ? 'Facebook'
          : 'the correct method';
      toast({
        title: 'Incorrect Login Method',
        description: `Please log in using ${loginMethod}.`,
        variant: 'destructive',
      });
      navigate('/user-actions/login');
    } else if (refreshToken !== null) {
      AXIOS.post(`/api/security/token/refresh`, { refreshToken: refreshToken })
        .then((res) => {
          const userData = res.data;
          setAuthState({
            user: userData.user,
            token: userData.token,
            tokenExpiration: userData.expiredAt,
            avatarUrl: userData.user.avatarUrl,
          });
          navigate('/');
        })
        .catch((err) => {
          console.log(err);
          toast({
            title: 'Token Refresh Error',
            description:
              'There was an issue refreshing your session. Please try again.',
            variant: 'destructive',
          });
          navigate('/user-actions/login');
        });
    } else {
      toast({
        title: 'Unknown Error',
        description: 'Nieznany błąd, spróbuj ponownie później.',
        variant: 'destructive',
      });
      navigate('/user-actions/login');
    }
  }, [setAuthState, navigate, searchParams, toast]);

  return null;
};

export default OAuthRedirectHandler;
