import { Stepper, Step, StepLabel } from '@mui/material';

import { StepperProps } from '@/types/types';

export default function StepperComponent({ activeStep }: StepperProps) {
  const steps = ['Koszyk', 'Dane i płatność', 'Podsumowanie'];

  return (
    <section className='w-4/6 mx-auto'>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel
              StepIconProps={{
                sx: {
                  '&.Mui-active': { color: '#f56c42' },
                  '&.Mui-completed': { color: '#f56c42' },
                },
              }}
            >
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </section>
  );
}
