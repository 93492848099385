import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import AXIOS from '../util/axios';

const NewsletterResignationPage: React.FC = () => {
  const [status, setStatus] = useState<
    'loading' | 'success' | 'error' | 'alreadyDisabled' | 'invalidCode'
  >('loading');
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  useEffect(() => {
    if (code) {
      AXIOS.post(`/api/newsletter/newsletter-recipients/disable/${code}`)
        .then((response) => {
          if (response.status === 200) {
            setStatus('success');
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            setStatus('alreadyDisabled');
          } else if (error.response && error.response.status === 404) {
            setStatus('invalidCode');
          } else {
            setStatus('error');
          }
        });
    } else {
      setStatus('invalidCode');
    }
  }, [code]);

  const handleReenable = () => {
    if (code) {
      AXIOS.get(`/api/newsletter/newsletter-recipient/enable/${code}`)
        .then((response) => {
          if (response.status === 200) {
            setStatus('success');
          }
        })
        .catch(() => {
          setStatus('error');
        });
    }
  };

  const renderContent = () => {
    switch (status) {
      case 'loading':
        return <p className='text-gray-600'>Loading...</p>;
      case 'success':
        return (
          <div className='flex flex-col justify-center items-center'>
            <p className='text-zinc-600'>
              Zrezygnowałeś właśnie z newslettera. Mamy nadzieję, że kiedyś
              wrócisz!
            </p>
            <button
              className='mt-4 px-4 py-2 bg-orange-600 hover:bg-orange-700 text-white rounded'
              onClick={handleReenable}
            >
              Anuluj rezygnację
            </button>
          </div>
        );
      case 'alreadyDisabled':
        return (
          <div className='flex justify-center items-center'>
            <p className='text-yellow-600'>
              Na Twój adres email nie otrzymujesz już newslettera.
            </p>
            <button
              className='mt-4 px-4 py-2 bg-orange-600 hover:bg-orange-700 text-white rounded'
              onClick={handleReenable}
            >
              Wróć do newslettera
            </button>
          </div>
        );
      case 'invalidCode':
        return <p className='text-red-600'>Kod jest niepoprawny.</p>;
      case 'error':
        return (
          <p className='text-red-600'>
            Wystąpił problem, spróbuj ponownie później.
          </p>
        );
      default:
        return null;
    }
  };

  return (
    <main className='flex flex-col items-center justify-start h-screen bg-white p-6'>
      <div className='w-56 md:w-72'>
        <img src='/mailx.webp' alt='ikonka kopery' />
      </div>
      <h1 className='text-2xl font-semibold text-center'>
        Rezygnacja z newslettera
      </h1>
      <div className='flex flex-col justify-center items-center'>
        {renderContent()}
      </div>
    </main>
  );
};

export default NewsletterResignationPage;
