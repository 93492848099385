export type AuthState = {
  status: 'idle' | 'loading' | 'success' | 'error';
  message: string;
  email?: string;
};

export type AuthAction =
  | { type: 'REQUEST' }
  | { type: 'SUCCESS'; message?: string }
  | { type: 'ERROR'; message: string }
  | { type: 'SET_EMAIL'; email: string };

const authReducer = (state: AuthState, action: AuthAction): AuthState => {
  switch (action.type) {
    case 'REQUEST':
      return { ...state, status: 'loading', message: 'Processing...' };
    case 'SUCCESS':
      return {
        ...state,
        status: 'success',
        message: action.message || 'Operation successful!',
      };
    case 'ERROR':
      return { ...state, status: 'error', message: action.message };
    case 'SET_EMAIL':
      return { ...state, email: action.email };
    default:
      return state;
  }
};

export default authReducer;
