import Newsletter from '@/components/lesson/Newsletter';
import { useAuth } from '@/hooks/useAuth';
import Header from '@/components/home/Header';
import Main from '@/components/home/Main';
import AuthorInfo from '@/components/home/AuthorInfo';
import FAQ from '@/components/home/FAQ';
import {useRef} from "react";

export default function Home() {
  const { authState } = useAuth();
  const scrollRef = useRef(null)

  return (
    <main className='relative flex flex-col flex-grow justify-evenly items-center w-screen'>
      <div className='absolute w-full h-full bg-white -z-20'/>
      <Header scrollRef={scrollRef}/>
      <div ref={scrollRef} className='flex flex-col relative w-screen justify-evenly items-center'>
        <div
          className="absolute w-full h-full bg-white bg-mainbg bg-cover bg-center -z-10"
          style={{ mask: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 10%, rgba(0,0,0,0.75) 90%, rgba(0,0,0,0) 100%)'}}
        />
        <div className='w-full mr-2 max-w-screen-xl px-4 pb-12'>
          <Main/>
          <AuthorInfo/>
          <FAQ/>
        </div>
      </div>
      {!authState && <Newsletter/>}
    </main>
  );
}
