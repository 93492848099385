import AXIOS from '@/util/axios';
import Loading from '@/pages/Loading';
import {useQuery} from '@tanstack/react-query';
import {CourseDataResponse} from '@/types/types';
import {useAuth} from "@/hooks/useAuth.tsx";
import CourseCard from "@components/courses/CourseCard.tsx";
import CourseCardProgress from "@components/courses/CourseCardProgress.tsx";

export default function Courses() {
  const {authState} = useAuth();
  const {data, error, isLoading} = useQuery<CourseDataResponse>({
    queryKey: ['courseData', authState],
    queryFn: async () => {
      const {data} = await AXIOS.get<CourseDataResponse>(
        '/api/courses/courses?page=1&sort[orderId]=asc'
      );
      return data;
    },
    
  });

  if (isLoading) return <Loading/>;
  if (error) return <div>Error: {error.message}</div>;

  const courses = data?._embedded.items;

  const ownedCourses = courses?.filter((course) => course.accessible);

  const purchaseAvailableCourses = courses?.filter(
    (course) => course.available && !course.accessible
  );

  const unavailableCourses = courses?.filter((course) => !course.available);

  return (
    <main className='flex flex-col w-full items-center justify-center'>
      <section className='flex flex-col w-full max-w-screen-xl pt-4'>
        {authState &&
          <section className='flex items-start flex-col px-2 mx-6 py-1'>
            <h2 className='text-zinc-700 font-semibold text-2xl ml-2'>
              Moje kursy
            </h2>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4'>
              {ownedCourses?.length ? (
                ownedCourses.map((course) => (
                  <CourseCardProgress
                    key={course.id}
                    id={course.id}
                    name={course.name}
                    description={course.description}
                    thumbnailUrl={course.thumbnailUrl}
                    currentChapter={course.progress!.currentChapter}
                    totalChapters={course.progress!.totalChapters}
                    currentLesson={course.progress!.currentLesson}
                    totalLessons={course.progress!.totalLessons}
                    currentLessonInChapter={course.progress!.currentLessonInChapter}
                    totalLessonsInChapter={course.progress!.totalLessonsInChapter}
                  />
                ))
              ) : (
                <p className='ml-2 text-zinc-600 w-full'>
                  Nie posiadasz obecnie żadnych kursów.
                </p>
              )}
            </div>
          </section>
        }

        {purchaseAvailableCourses?.length ? (
          <section className='px-2 mx-6 py-12'>
            <h2 className='text-zinc-700 font-semibold text-2xl ml-2'>
              Dostępne kursy
            </h2>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4'>
              {purchaseAvailableCourses.map((course) => (
                <CourseCard
                  key={course.id}
                  id={course.id}
                  name={course.name}
                  description={course.description}
                  thumbnailUrl={course.thumbnailUrl}
                  price={course.priceFinal}
                  regularPrice={course.isDiscounted ? course.priceRegular : null}
                  available={true}
                />
              ))}
            </div>
          </section>
        ) : null }

        <section className='px-2 mx-6 py-12'>
          <h2 className='text-zinc-700 font-semibold text-2xl ml-2'>
            Wkrótce dostępne
          </h2>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4'>
            {unavailableCourses?.map((course) => (
              <CourseCard
                key={course.id}
                id={course.id}
                name={course.name}
                description={course.description}
                thumbnailUrl={course.thumbnailUrl}
                price={course.priceFinal}
                regularPrice={course.discountAmount === "0.00zł" ? null : course.priceRegular}
                available={false}
              />
            ))}
          </div>
        </section>
      </section>
    </main>
  );
}
