import { ModalProps } from '@/types/types';

export const Modal: React.FC<ModalProps> = ({ message, onClose, children }) => {
  return (
    <div className='fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50'>
      <div className='bg-white p-6 rounded-md shadow-md w-1/2 relative'>
        <button
          className='absolute top-2 right-2 text-gray-500 hover:text-gray-800'
          onClick={onClose}
        >
          &times;
        </button>
        <p className='text-center font-semiboldtext-zinc-700 text-xl'>
          {message}
        </p>
        {children && <div className='mt-4'>{children}</div>}
      </div>
    </div>
  );
};
