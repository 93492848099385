import { useState } from 'react';
import {
  Typography,
  Button,
  TextField,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link, useNavigate } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import { MuiTelInput } from 'mui-tel-input';

import StepperComponent from '@/components/StepperComponent';
import { FormData, CheckoutFormError, PaymentMethod } from '@/types/types';
import AXIOS from '@/util/axios';

//static styles
const GrayTextField = styled(TextField)(() => ({
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E0E3E7',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6F7E8C',
    },
    '& input': {
      backgroundColor: '#f5f5f5',
      fontSize: '0.6rem',
    },
  },
}));

const StyledMuiTelInput = styled(MuiTelInput)(() => ({
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#E0E3E7',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6F7E8C',
    },
    '& input': {
      backgroundColor: '#f5f5f5',
    },
  },
}));

const PaymentButton = styled(Button)<{ selected: boolean }>(({ selected }) => ({
  border: selected ? '2px solid #bdbdbd' : '2px solid transparent',
  transition: 'all 300ms ease',
  margin: '10px',
  padding: '16px',
  borderRadius: '8px',
  backgroundColor: selected ? '#f0f0f0' : '#fafafa',
  color: selected ? '#616161' : '#9e9e9e',
  '&:hover': {
    borderColor: '#bdbdbd',
    backgroundColor: selected ? '#e0e0e0' : '#f5f5f5',
    color: '#616161',
  },
  '&:active': {
    backgroundColor: '#d6d6d6',
    borderColor: '#bdbdbd',
  },
  '&:focus': {
    outline: 'none',
  },
  '&.Mui-focusVisible': {
    outline: 'none',
  },
}));

export default function CheckoutForm() {
  //states
  const [formData, setFormData] = useState<FormData>(() => {
    const savedData = localStorage.getItem('orderData');
    return savedData
      ? JSON.parse(savedData)
      : {
          paymentMethod: '',
          customerData: {
            type: 'individual',
            nip: '',
            firstName: '',
            lastName: '',
            name: '',
            city: '',
            postalCode: '',
            street: '',
            houseNumber: '',
            phoneNumber: '',
            email: '',
            loading: false,
            selectedPaymentMethod: '',
          },
        };
  });

  const [errors, setErrors] = useState<CheckoutFormError>();
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    string | null
  >(null);
  const [paymentMethodError, setPaymentMethodError] = useState<string | null>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false);

  const hardcodedPaymentMethods: PaymentMethod[] = [
    {
      id: 1,
      name: 'Karta kredytowa',
      imageUrl:
        'https://www.przelewy24.pl/themes/przelewy24/assets/img/metody-platnosci/glowne/karty.svg?26092022',
    },
    {
      id: 2,
      name: 'Przelewy24',
      imageUrl:
        'https://www.przelewy24.pl/themes/przelewy24/assets/img/base/przelewy24_logo_2022.svg',
    },
    {
      id: 3,
      name: 'GooglePay',
      imageUrl:
        'https://www.przelewy24.pl/themes/przelewy24/assets/img/metody-platnosci/glowne/googlepay.svg',
    },
    {
      id: 4,
      name: 'ApplePay',
      imageUrl:
        'https://woocommerce.pl/wp-content/uploads/2021/06/pay-apple.png',
    },
    {
      id: 5,
      name: 'blik',
      imageUrl:
        'https://www.przelewy24.pl/themes/przelewy24/assets/img/metody-platnosci/glowne/blik.svg',
    },
  ];

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      customerData: {
        ...prevData.customerData,
        [name]: value,
      },
    }));
  };

  const handleToggleClientTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      customerData: {
        ...prevData.customerData,
        type: event.target.checked ? 'company' : 'individual',
      },
    }));
  };

  const handlePhoneChange = (newValue: string) => {
    const sanitizedValue = newValue.replace(/[^0-9+]/g, '');
    setFormData((prevData) => ({
      ...prevData,
      customerData: {
        ...prevData.customerData,
        phoneNumber: sanitizedValue,
      },
    }));
  };

  const validate = (): boolean => {
    const newErrors: CheckoutFormError = {
      lastName: '',
      firstName: '',
      nip: '',
      name: '',
      city: '',
      postalCode: '',
      street: '',
      houseNumber: '',
      phoneNumber: '',
      email: '',
      selectedPaymentMethod: '',
    };

    if (formData.customerData.type === 'company') {
      if (!isValidNip(formData.customerData.nip)) {
        newErrors.nip = 'NIP jest niepoprawny';
      }
      if (!formData.customerData.name) {
        newErrors.name = 'Nazwa firmy jest wymagana';
      }
    } else {
      if (!formData.customerData.firstName)
        newErrors.firstName = 'Imię jest wymagane';
      if (!formData.customerData.lastName)
        newErrors.lastName = 'Nazwisko jest wymagane';
    }

    if (!formData.customerData.city) newErrors.city = 'Miasto jest wymagane';
    if (!/^(\d{2}-\d{3})$/.test(formData.customerData.postalCode)) {
      newErrors.postalCode = 'Kod pocztowy jest niepoprawny';
    }
    if (!formData.customerData.street) newErrors.street = 'Ulica jest wymagana';
    if (!formData.customerData.houseNumber)
      newErrors.houseNumber = 'Numer domu jest wymagany';
    if (!/^\+\d{1,3} ?\d{9,}$/.test(formData.customerData.phoneNumber)) {
      newErrors.phoneNumber =
        'Numer telefonu musi zaczynać się od kodu kraju, np. +48 123456789';
    }
    if (!/\S+@\S+\.\S+/.test(formData.customerData.email)) {
      newErrors.email = 'Email jest niepoprawny';
    }

    if (!selectedPaymentMethod) {
      newErrors.selectedPaymentMethod = 'Musisz wybrać metodę płatności';
      setPaymentMethodError('Musisz wybrać metodę płatności');
    } else {
      setPaymentMethodError(null);
    }

    setErrors(newErrors);

    return Object.values(newErrors).every((error) => error === '');
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validate()) {
      return;
    }

    const orderData = {
      ...formData,
      selectedPaymentMethod,
    };

    if (formData.customerData.type === 'individual') {
      const fullName = `${formData.customerData.firstName} ${formData.customerData.lastName}`;
      orderData.customerData.name = fullName;
    } else {
      orderData.customerData.name = formData.customerData.name;
    }

    localStorage.setItem('orderData', JSON.stringify(orderData));

    goToSummary();
  };

  const navigate = useNavigate();

  function goToSummary() {
    navigate('/cart/order-summary');
  }

  const isValidNip = (value: string): boolean => {
    const nip = value.replace(/[^0-9]/g, '');
    if (nip.length !== 10) return false;

    const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
    const sum = [...nip]
      .slice(0, 9)
      .reduce((acc, digit, i) => acc + weights[i] * Number(digit), 0);
    const controlNumber = (sum % 11) % 10;

    return controlNumber === Number(nip[9]);
  };

  const fetchCompanyData = async () => {
    if (!isValidNip(formData.customerData.nip)) {
      alert('NIP jest niepoprawny');
      return;
    }

    setLoading(true);

    try {
      const response = await AXIOS.get(
        `/api/gus/company/${formData.customerData.nip}`
      );
      if (response.status !== 200) {
        throw new Error('Błąd w trakcie pobierania danych');
      }
      const data = response.data;
      setFormData((prevData) => ({
        ...prevData,
        customerData: {
          ...prevData.customerData,
          name: data.name || prevData.customerData.name,
          city: data.city || prevData.customerData.city,
          postalCode: data.postalCode || prevData.customerData.postalCode,
          street: data.street || prevData.customerData.street,
          houseNumber: data.propertyNumber || prevData.customerData.houseNumber,
        },
      }));
    } catch (error) {
      console.error('Error fetching company data:', error);
      alert('Nie udało się pobrać danych z GUS.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='flex flex-col justify-center min-h-[calc(100vh-218px)]'>
      <div className='flex flex-col sm:flex-row md:flex-row flex-1'>
        <div className='flex flex-col justify-center items-center md:py-4 sm:py-4 py-10 md:px-4 sm:px-4 px-6 w-full md:w-2/3'>
          <StepperComponent activeStep={1} />
          <form
            onSubmit={handleSubmit}
            className='w-full md:w-2/3 sm:w-2/3 mt-6'
          >
            <Typography variant='h5' gutterBottom>
              Dane odbiorcy
            </Typography>

            <FormControlLabel
              control={
                <Switch
                  checked={formData.customerData.type === 'company'}
                  onChange={handleToggleClientTypeChange}
                  color='default'
                />
              }
              label={
                formData.customerData.type === 'company'
                  ? 'Kupuję jako firma'
                  : 'Kupuję jako osoba prywatna'
              }
            />

            {formData.customerData.type === 'company' && (
              <div className='flex items-center justify-center mt-10'>
                <GrayTextField
                  label='NIP'
                  name='nip'
                  value={formData.customerData.nip}
                  onChange={handleChange}
                  error={!!errors?.nip}
                  helperText={errors?.nip}
                  fullWidth
                  margin='normal'
                  variant='outlined'
                  style={{ flexGrow: 1 }}
                  tabIndex={1}
                />
                <Button
                  variant='contained'
                  onClick={fetchCompanyData}
                  disabled={!isValidNip(formData.customerData.nip) || loading}
                  style={{
                    marginLeft: '10px',
                    marginTop: '5px',
                    height: '54px',
                    width: '54px',
                    minWidth: 'unset',
                    backgroundColor:
                      isValidNip(formData.customerData.nip) && !loading
                        ? '#f56c42'
                        : '#e0e0e0',
                    color:
                      isValidNip(formData.customerData.nip) && !loading
                        ? '#fff'
                        : '#9e9e9e',
                  }}
                >
                  {loading ? (
                    <CircularProgress size={16} style={{ color: '#fff' }} />
                  ) : (
                    <SearchIcon />
                  )}
                </Button>
              </div>
            )}

            {formData.customerData.type === 'individual' && (
              <div className='flex items-center justify-center'>
                <GrayTextField
                  label='Imię'
                  name='firstName'
                  value={formData.customerData.firstName || ''}
                  onChange={handleChange}
                  error={!!errors?.firstName}
                  helperText={errors?.firstName}
                  fullWidth
                  margin='normal'
                  variant='outlined'
                  tabIndex={1}
                />
                <GrayTextField
                  label='Nazwisko'
                  name='lastName'
                  value={formData.customerData.lastName || ''}
                  onChange={handleChange}
                  error={!!errors?.lastName}
                  helperText={errors?.lastName}
                  fullWidth
                  margin='normal'
                  variant='outlined'
                  style={{ marginLeft: '10px' }}
                  tabIndex={2}
                />
              </div>
            )}

            {formData.customerData.type === 'company' && (
              <div className='flex items-center'>
                <GrayTextField
                  label='Nazwa firmy'
                  name='name'
                  value={formData.customerData.name || ''}
                  onChange={handleChange}
                  error={!!errors?.name}
                  helperText={errors?.name}
                  fullWidth
                  margin='normal'
                  variant='outlined'
                  tabIndex={2}
                />
              </div>
            )}

            <GrayTextField
              label='Miasto'
              name='city'
              value={formData.customerData.city || ''}
              onChange={handleChange}
              error={!!errors?.city}
              helperText={errors?.city}
              fullWidth
              margin='normal'
              variant='outlined'
              tabIndex={3}
            />

            <GrayTextField
              label='Kod pocztowy (XX-XXX)'
              name='postalCode'
              value={formData.customerData.postalCode || ''}
              onChange={handleChange}
              error={!!errors?.postalCode}
              helperText={errors?.postalCode}
              fullWidth
              margin='normal'
              variant='outlined'
              tabIndex={4}
            />

            <div className='flex gap-1'>
              <GrayTextField
                label='Ulica'
                name='street'
                value={formData.customerData.street || ''}
                onChange={handleChange}
                error={!!errors?.street}
                helperText={errors?.street}
                fullWidth
                margin='normal'
                variant='outlined'
                style={{ flexGrow: 2 }}
                tabIndex={5}
              />
              <GrayTextField
                label='Numer'
                name='houseNumber'
                value={formData.customerData.houseNumber || ''}
                onChange={handleChange}
                error={!!errors?.houseNumber}
                helperText={errors?.houseNumber}
                margin='normal'
                variant='outlined'
                style={{ flexGrow: 1 }}
                tabIndex={6}
              />
            </div>

            <StyledMuiTelInput
              label='Numer telefonu'
              name='phoneNumber'
              value={formData.customerData.phoneNumber || ''}
              onChange={handlePhoneChange}
              error={!!errors?.phoneNumber}
              helperText={errors?.phoneNumber}
              fullWidth
              margin='normal'
              variant='outlined'
              defaultCountry='PL'
              tabIndex={7}
            />
            <GrayTextField
              label='Email'
              name='email'
              value={formData.customerData.email || ''}
              onChange={handleChange}
              error={!!errors?.email}
              helperText={errors?.email}
              fullWidth
              margin='normal'
              variant='outlined'
              tabIndex={8}
            />

            <div className='flex justify-between mt-4'>
              <Button
                component={Link}
                to='/cart'
                style={{ backgroundColor: '#f56c42', color: '#fff' }}
              >
                Wróć
              </Button>

              <Button
                variant='contained'
                type='submit'
                color='primary'
                style={{ backgroundColor: '#f56c42', color: '#fff' }}
                className='bg-[#f56c42] text-white transition duration-300 ease-in-out hover:bg-[#ff8c5a] hover:text-white'
              >
                Dalej
              </Button>
            </div>
          </form>
        </div>

        <div className='flex flex-col justify-center items-center w-full md:w-1/2 sm:w-1/2 p-4 bg-gray-100'>
          <Typography variant='h5'>Wybierz metodę płatności</Typography>
          <div className='grid grid-cols-2 gap-4 mt-10'>
            {hardcodedPaymentMethods.map((method) => (
              <PaymentButton
                key={method.id}
                selected={selectedPaymentMethod === method.name}
                onClick={() => setSelectedPaymentMethod(method.name)}
                style={{
                  width: '120px',
                  height: '120px',
                  maxWidth: '120px',
                  maxHeight: '120px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img
                  src={method.imageUrl}
                  alt={method.name}
                  style={{
                    width: '80px',
                    height: '80px',
                    objectFit: 'contain',
                  }}
                />
              </PaymentButton>
            ))}
          </div>

          {paymentMethodError && (
            <Typography color='error'>{paymentMethodError}</Typography>
          )}
        </div>
      </div>
    </div>
  );
}
