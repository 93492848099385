import { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { AuthProviderProps, AuthStateType } from '@/types/types';
import AuthContext from './AuthCtx';
import AXIOS from '@/util/axios';
import fetchToken from '@/util/FetchToken';
import { redirect } from 'react-router-dom';
import { InternalAxiosRequestConfig } from 'axios'; // Import właściwego typu z Axiosa

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [authState, setAuthState] = useState<AuthStateType>(() => {
    const authStateCookie = Cookies.get('authState');
    return authStateCookie ? JSON.parse(authStateCookie) : null;
  });

  const [interceptorLock, setInterceptorLock] = useState(false);

  const tokenExpirationThreshold = 30000;

  useEffect(() => {
    // console.log('effect - update auth state')
    if (authState) {
      Cookies.set('authState', JSON.stringify(authState));
    } else {
      Cookies.remove('authState');
    }
  });

  useEffect(() => {
    const authInterceptor = AXIOS.interceptors.request.use(
      requestInterceptor,
      requestErrorInterceptor
    );

    return () => {
      AXIOS.interceptors.request.eject(authInterceptor);
    };
  })

  const requestInterceptor = async (config: InternalAxiosRequestConfig) => {
    if (config.url === '/api/security/token/refresh') {
      return config;
    }

    if (!authState?.token) {
      return config;
    }

    if (isTokenExpired(authState)) {
      try {
        if (!interceptorLock) {
          setInterceptorLock(true);
          await tryRefreshToken();
        }
      } catch (e) {
        logout();
      } finally {
        setInterceptorLock(false);
      }
    }

    if (authState?.token) {
      config.headers.Authorization = `Bearer ${authState.token}`;
    }

    return config;
  };

  const requestErrorInterceptor = async (error: ErrorCallback) =>
    Promise.reject(error);

  function isTokenExpired(authState: AuthStateType): boolean {
    return (
      (authState?.token &&
        new Date(authState.tokenExpiration).getTime() <
          new Date().getTime() + tokenExpirationThreshold) ||
      false
    );
  }

  const tryRefreshToken = async () => {
    const refreshTokenCookie = Cookies.get('authRefreshToken');
    const refreshToken = refreshTokenCookie
      ? JSON.parse(refreshTokenCookie)
      : null;

    if (!refreshToken) {
      throw new Error('No refresh token available');
    }

    if (
      new Date(refreshToken.tokenExpiration).getTime() * 1000 <
      new Date().getTime()
    ) {
      throw new Error('Refresh token is expired');
    }

    const response = await AXIOS.post('/api/security/token/refresh', {
      refreshToken: refreshToken.token,
    });

    if (response.status !== 201) {
      throw new Error('Token refreshing failed');
    }

    setAuthState({
      user: {
        id: response.data.user.id,
        email: response.data.user.email,
        displayName: response.data.user.displayName,
        isSuperUser: response.data.user.isSuperUser,
        roles: response.data.user.roles,
        avatarUrl: response.data.user.avatarUrl,
        cart: response.data.user.cart,
      },
      token: response.data.token,
      tokenExpiration: response.data.expiredAt,
      avatarUrl: response.data.user.avatarUrl,
    });

    Cookies.set(
      'authRefreshToken',
      JSON.stringify({
        token: response.data.refreshToken,
        tokenExpiration: response.data.refreshTokenExpiration,
      })
    );
  };

  const login = async (email: string, password: string) => {
    const data = await fetchToken(email, password);
    setAuthState({
      user: {
        id: data.user.id,
        email: data.user.email,
        displayName: data.user.displayName,
        isSuperUser: data.user.isSuperUser,
        roles: data.user.roles,
        avatarUrl: data.user.avatarUrl,
        cart: data.user.cart,
      },
      token: data.token,
      tokenExpiration: data.expiredAt,
      avatarUrl: data.user.avatarUrl,
      password: password,
    });

    Cookies.set(
      'authRefreshToken',
      JSON.stringify({
        token: data.refreshToken,
        tokenExpiration: data.refreshTokenExpiration,
      })
    );
  };

  const logout = (callback?: () => void) => {
    Cookies.remove('authState');
    Cookies.remove('authRefreshToken');
    setAuthState(null);
    if (callback) callback();
    redirect('/');
  };

  return (
    <AuthContext.Provider value={{ setAuthState, authState, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
