import { useQuery } from '@tanstack/react-query';
import DOMPurify from 'dompurify';

import '@/styles/site.css';
import { Lesson, LessonId } from '@/types/types';
import { useAuth } from '@/hooks/useAuth';
import AXIOS from '@/util/axios';
import Loading from '@/pages/Loading';

const MDNote = ({ lessonId }: LessonId) => {
  const { authState } = useAuth();

  const {
    data: lessonData,
    error: lessonError,
    isLoading: lessonLoading,
  } = useQuery<Lesson>({
    queryKey: ['lessonData', lessonId],
    queryFn: async () => {
      const { data } = await AXIOS.get(`/api/courses/lessons/${lessonId}`);
      return data;
    },
    enabled: !!authState?.token && !!lessonId,
  });

  if (lessonLoading) return <Loading />;

  if (lessonError) return 'An Error occurred: ' + lessonError.message;

  const cleanHTML = DOMPurify.sanitize(lessonData?.text || '');

  return (
    <div className='theme-default aui-theme-default'>
      <div dangerouslySetInnerHTML={{ __html: cleanHTML }} />
    </div>
  );
};

export default MDNote;
