import ReactPlayer from 'react-player';
import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { url } from './url';
import LessonMenu from '@/components/lesson/LessonMenu';
import LessonNav from '@/components/lesson/LessonNav';
import { useLesson } from '@/hooks/useLesson';
import { LessonProvider } from '@/context/LessonProvider';
import Loading from '../../pages/Loading';

function LessonContent() {
  const { lessonId } = useParams<{ lessonId: string }>();
  const { currentLessonId, setCurrentLessonId } = useLesson();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (lessonId) {
      setCurrentLessonId(Number(lessonId));
    }
  }, [lessonId, setCurrentLessonId]);

  useEffect(() => {
    if (currentLessonId && lessonId !== currentLessonId.toString()) {
      navigate(`/lesson/${currentLessonId}`, { replace: true });
    }
  }, [currentLessonId, lessonId, navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, [currentLessonId]);

  return (
    <main className='flex flex-grow justify-center items-center'>
      {isLoading ? (
        <Loading />
      ) : (
        <section className='flex flex-col lg:flex-row w-full justify-center items-stretch h-full'>
          <div className='lg:w-full flex flex-col justify-start items-center '>
            <div
              className='relative w-full'
              style={{ paddingBottom: '56.25%' }}
            >
              <div className='absolute top-0 left-0 w-full h-full'>
                <ReactPlayer
                  url={url[currentLessonId]?.url || ''}
                  controls={true}
                  width='100%'
                  height='100%'
                />
              </div>
            </div>

            <div className='w-full'>
              <LessonNav />
            </div>
          </div>
          <div className='lg:w-1/3 flex justify-center items-center h-full'>
            <LessonMenu />
          </div>
        </section>
      )}
    </main>
  );
}

export default function Lesson() {
  return (
    <LessonProvider>
      <LessonContent />
    </LessonProvider>
  );
}
