import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import AXIOS from '@/util/axios';

const NewsletterConfirmationPage: React.FC = () => {
  const [status, setStatus] = useState<
    'loading' | 'success' | 'error' | 'alreadyConfirmed' | 'invalidCode'
  >('loading');
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  useEffect(() => {
    if (code) {
      AXIOS.post(`/api/newsletter/newsletter-recipients/confirm/${code}`)
        .then(response => {
          if (response.status === 200) {
            setStatus('success');
          }
        })
        .catch((error) => {
          if (error.response && error.response.status === 400) {
            setStatus('alreadyConfirmed');
          } else if (error.response && error.response.status === 404) {
            setStatus('invalidCode');
          } else {
            setStatus('error');
          }
        });
    } else {
      setStatus('invalidCode');
    }
  }, [code]);

  const renderContent = () => {
    switch (status) {
      case 'loading':
        return <p className='text-gray-600'>Loading...</p>;
      case 'success':
        return (
          <p className='text-green-600'>
            Twój email został potwierdzony. Dziękujemy!
          </p>
        );
      case 'alreadyConfirmed':
        return (
          <p className='text-yellow-600'>Twój email został już potwierdzony.</p>
        );
      case 'invalidCode':
        return <p className='text-red-600'>Błędny kod.</p>;
      case 'error':
        return (
          <p className='text-red-600'>
            Wystąpił problem, spróbuj ponownie później.
          </p>
        );
      default:
        return null;
    }
  };

  return (
    <main className='flex flex-col items-center justify-start h-screen bg-white'>
      <div className='w-56 md:w-72'>
        <img src='/mail.webp' alt='ikonka koperty' />
      </div>

      <h1 className='text-2xl font-semibold text-center'>
        Potwierdzenie rejestracji do newslettera
      </h1>
      {renderContent()}
    </main>
  );
};

export default NewsletterConfirmationPage;
