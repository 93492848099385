import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import AXIOS from '@/util/axios';
import { CourseDataResponse } from '@/types/types';
import { useLesson } from '@/hooks/useLesson';
import Loading from '@/pages/Loading';

export default function Main() {
  const { currentLessonId } = useLesson();
  const navigate = useNavigate();

  const { data, error, isLoading } = useQuery<CourseDataResponse>({
    queryKey: ['courseData'],
    queryFn: async () => {
      const { data } = await AXIOS.get<CourseDataResponse>(
        '/api/courses/courses?page=1&limit=4&sort[orderId]=asc'
      );
      return data;
    },
  });

  if (isLoading) return <Loading />;
  if (error) return <div>Error: {error.message}</div>;

  const courses = data?._embedded.items;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleCourseClick = (course: any) => {
    if (course.available && !course.accessible) {
      navigate(`/courses/course-details/${course.id}`);
    } else if (course.available) {
      navigate(`/courses/${course.id}/lesson/${currentLessonId}`);
    }
  };

  return (
    <main className='flex flex-col items-center justify-center py-6 px-4'>
      <section className='flex flex-col sm:flex-row justify-center items-center sm:items-stretch w-full'>
        <section className='p-8 bg-gray-100 sm:rounded-tl-[0%] sm:rounded-br-[0%] sm:rounded-[20%] rounded-xl max-w-lg m-4 flex-1 w-full sm:w-auto sm:items-start text-left'>
          <h2 className='font-bold text-lg'>
            Dlaczego <strong className='text-[#ff7a4e]'>nasze</strong> kursy?
          </h2>
          <ul>
            <li
              className="relative pl-6 before:content-[''] before:absolute before:left-0.5 before:top-2 before:w-2 before:h-2 before:bg-[#ff7a4e] before:rounded-full mb-6">
              <strong>Środowiska ćwiczeniowe w chmurze</strong>, pozwalające praktykować zawsze i wszędzie - bez zbędnej
              konfiguracji maszyn lokalnych.
            </li>
            <li
              className="relative pl-6 before:content-[''] before:absolute before:left-0.5 before:top-2 before:w-2 before:h-2 before:bg-[#ff7a4e] before:rounded-full mb-6">
              Filmy tłumaczące wszystkie zagadnienia <strong>z praktycznej perspektywy</strong>.
            </li>
            <li
              className="relative pl-6 before:content-[''] before:absolute before:left-0.5 before:top-2 before:w-2 before:h-2 before:bg-[#ff7a4e] before:rounded-full mb-6">
              Materiały - <strong>teksty zawierające najważniejsze zagadnienia</strong>, umożliwiające szybką powtórkę i
              podsumowanie najważniejszych informacji.
            </li>
            <li
              className="relative pl-6 before:content-[''] before:absolute before:left-0.5 before:top-2 before:w-2 before:h-2 before:bg-[#ff7a4e] before:rounded-full mb-6">
              <strong>Godzina indywidualnej konsultacji</strong> z doświadczonym devops'em w cenie kursu - do
              wykorzystania w dowolny sposób.
            </li>
            <li
              className="relative pl-6 before:content-[''] before:absolute before:left-0.5 before:top-2 before:w-2 before:h-2 before:bg-[#ff7a4e] before:rounded-full mb-6">
              Liczne <strong>ćwiczenia praktyczne</strong> ułatwiające skuteczne przyswajanie wiedzy
            </li>
            <li
              className="relative pl-6 before:content-[''] before:absolute before:left-0.5 before:top-2 before:w-2 before:h-2 before:bg-[#ff7a4e] before:rounded-full mb-6">
              Pełny zakres materiału - przyjazny zarówno dla osób <strong>początkujących</strong> jak i osób, które mają już pojęcie w <strong>tematyce devops</strong>.
            </li>
          </ul>
        </section>
        <section className='py-6 px-4 flex-1'>
          <aside className='mb-6'>
            <h2 className='text-2xl font-bold'>Kursy w naszej ofercie</h2>
            <hr className='h-1 bg-[#ff7a4e] mt-[-1.5em]'/>
          </aside>
          <div className='grid grid-cols-1 sm:grid-cols-2 gap-2 w-full'>
          {courses?.map((course, index) => (
              <div
                key={course.id}
                className={`p-4 transform transition-transform duration-200 bg-gray-200
                  ${
                    course.available
                      ? 'cursor-pointer hover:scale-95 hover:shadow-lg'
                      : 'opacity-50 cursor-not-allowed'
                  }
                  w-full
                  ${
                    index === 0
                      ? 'rounded-tl-[8%] sm:rounded-tr-none rounded-tr-[8%]'
                      : index === 1
                      ? 'sm:rounded-tr-[2%] rounded-tr-none'
                      : index === 2
                      ? 'rounded-bl-none sm:rounded-bl-[2%]'
                      : 'sm:rounded-bl-none rounded-bl-[8%] rounded-br-[8%]'
                  }`}
                onClick={() => course.available && handleCourseClick(course)}
              >
                <div className='relative '>
                  <img
                    src={course.thumbnailUrl}
                    alt={course.name}
                    className={`w-full object-cover rounded-md ${
                      course.available ? '' : 'grayscale'
                    }`}
                  />
                  <p className='pt-1 w-full text-center text-md font-medium text-gray-600'>{course.description}</p>
                  <div className='flex justify-center mt-4'>
                    <button className='bg-[#ff7a4e] text-white text-xs px-4 py-2 rounded-lg'>
                      {course.available ? 'Poznaj szczegóły' : 'Wkrótce dostępny'}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </section>
    </main>
  );
}
