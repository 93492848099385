import { NotificationFormProps } from '@/types/types';

const EmailNotificationPage: React.FC<NotificationFormProps> = ({
  message,
}) => {
  return (
    <div className='flex flex-col items-center p-12 shadow-md rounded-md bg-white mx-12 min-w-96'>
      <p className='text-gray-700'>{message}</p>
    </div>
  );
};

export default EmailNotificationPage;
