import { FaGithubSquare, FaLinkedin,FaPhoneSquareAlt, FaEnvelopeSquare } from 'react-icons/fa';

export default function AuthorInfo() {
  return (
    <section className="flex flex-col justify-center items-center w-full py-6 px-4">
      <div className="flex sm:flex-row flex-col justify-center items-center border-t-[3px] border-b-[3px] border-[#ff7a4e]">
        <div className="flex w-full flex-col px-2 pt-12 sm:pb-12 pb-4">
          <div className="w-full self-start text-3xl font-bold md:text-4xl pb-2">
            O prowadzącym
          </div>
          <div className="flex flex-col">
            <div className="my-4 w-full font-light md:pr-4 md:text-xl xl:text-xl text-justify">
              Cześć, nazywam się Kamil - obecnie jestem inżynierem devops i konsultantem w zakresie technicznej strony inżynierii oprogramowania.
              <br/><br/>

              Dzięki praktyce w wdrażaniu infrastruktur dla aplikacji od A-Z - poczynając od doboru serwerów,
              przez projekt i automatyzację procesów wydawniczych, a w końcu wdrażając systemy umożliwiające monitorowanie aplikacji na wielu poziomach -
              nabyłem umiejętności, które mogę przekazać dalej.
            </div>
            <div className='my-2 flex justify-center text-5xl md:justify-start md:text-4xl '>
              <div className="mr-2 transition-colors duration-200 hover:text-purple-400">
                <a href="https://github.com/pfaffkam" target='_blank' rel='noreferrer' aria-label="Profil na GitHub">
                  <FaGithubSquare/>
                </a>
              </div>
              <div className="mx-2 transition-colors duration-200 hover:text-blue-800">
                <a href="https://www.linkedin.com/in/kamil-pfaff/" target='_blank' rel='noreferrer' aria-label="LinkedIn">
                  <FaLinkedin/>
                </a>
              </div>
              <div className="mx-2 transition-colors duration-200 hover:text-green-400">
                <a href="tel:+48533663534" target='_blank' rel='noreferrer' aria-label="Numer telefonu">
                  <FaPhoneSquareAlt/>
                </a>
              </div>
              <div className="mx-2 transition-colors duration-200 hover:text-red-400">
                <a href="mailto:kamil@pfaff.app" target='_blank' rel='noreferrer' aria-label="Kontakt mailowy">
                  <FaEnvelopeSquare/>
                </a>
              </div>
            </div>
          </div>
        </div>
        <img src="./selfphoto.webp" alt="" className="ml-6 sm:mt-12 mb-12 max-h-[315px] max-w-[315px] rounded-3xl drop-shadow-md mr-2"/>
      </div>
    </section>
  );
}
