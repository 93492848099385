import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function FAQ() {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const faqs = [
    {
      question: 'Co zrobić jeżeli napotkam problemy techniczne podczas kursu?',
      answer:
        'lorem ipsum dolor sit amet, consectetur adipsicing jhfehfiwhe jduiendw, ujnauw.',
    },
    {
      question: 'Jaka jest forma kursu?',
      answer:
        'lorem ipsum dolor sit amet, consectetur adipsicing jhfehfiwhe jduiendw, ujnauw.',
    },
    {
      question: 'Co otrzymam po zakupieniu kursu?',
      answer:
        'lorem ipsum dolor sit amet, consectetur adipsicing jhfehfiwhe jduiendw, ujnauw.',
    },
    {
      question: 'Czy dostęp do kursu jest molżliwy z całego świata?',
      answer:
        'lorem ipsum dolor sit amet, consectetur adipsicing jhfehfiwhe jduiendw, ujnauw.',
    },
    {
      question: 'Jak działa wirtualna maszyna załączana z kursem?',
      answer:
        'lorem ipsum dolor sit amet, consectetur adipsicing jhfehfiwhe jduiendw, ujnauw.',
    },
    {
      question: 'Na jaki okres czasu otrzymam kurs?',
      answer:
        'lorem ipsum dolor sit amet, consectetur adipsicing jhfehfiwhe jduiendw, ujnauw.',
    },
    {
      question: 'Czy mogę wrócić do materiałów po ukończeniu kursu?',
      answer:
        'lorem ipsum dolor sit amet, consectetur adipsicing jhfehfiwhe jduiendw, ujnauw.',
    },
    {
      question: 'Czy muszę mieć wcześniejsze doświadczenie, aby zacząć?',
      answer:
        'lorem ipsum dolor sit amet, consectetur adipsicing jhfehfiwhe jduiendw, ujnauw.',
    },
  ];

  const handleToggle = (index: number) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <main className='flex flex-col justify-center items-center px-2 py-6 mx-4 sm:mx-20 border-t-[3px] border-[#ff7a4e]'>
      <div className='flex flex-col items-center justify-center text-center'>
        <h3 className='text-lg font-bold uppercase mb-[-2em]'>
          najczęściej zadawane
        </h3>
        <h2 className='text-3xl font-bold mb-4'>Pytania i odpowiedzi</h2>
      </div>

      <div className='space-y-2 mx-2'>
        {faqs.map((faq, index) => (
          <div
            key={index}
            className='bg-[#fa9f81] bg-opacity-40 rounded-lg shadow-md overflow-hidden transition-all duration-300 border-2 border-[#ee6537]'
          >
            <button
              className='flex justify-between items-center w-full p-4 text-left text-zinc-800 focus:outline-none'
              onClick={() => handleToggle(index)}
            >
              <span className='font-semibold'>{faq.question}</span>
              {activeIndex === index ? (
                <ExpandLessIcon className='h-5 w-5 text-zinc-800' />
              ) : (
                <ExpandMoreIcon className='h-5 w-5 text-zinc-800' />
              )}
            </button>
            <div
              className={`overflow-hidden transition-max-height duration-500 ease-in-out ${
                activeIndex === index ? 'max-h-40' : 'max-h-0'
              }`}
            >
              <div
                className={`p-4 bg-[#fa9f81] bg-opacity-5 text-zinc-800 transition-opacity duration-300 ease-in-out font-semibold ${
                  activeIndex === index ? 'opacity-100' : 'opacity-0'
                }`}
              >
                <p>{faq.answer}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </main>
  );
}
