import { useState } from 'react';

import Notes from './Notes';
import MDNote from './MDNote';
import { useLesson } from '@/hooks/useLesson';

export default function LessonNav() {
  const [selectedButton, setSelectedButton] = useState('courseInfo');
  const { currentLessonId } = useLesson();

  function handleOpen(button: string) {
    setSelectedButton(button);
  }

  let selectedComponent;
  switch (selectedButton) {
    case 'notes':
      selectedComponent = <Notes />;
      break;
    case 'docs':
      selectedComponent = <MDNote lessonId={currentLessonId} />;
      break;
    default:
      selectedComponent = <MDNote lessonId={currentLessonId} />;
  }

  return (
    <nav>
      <div className='flex justify-end w-full py-2 px-4 bg-slate-100 text-lowercase'>
        <button
          className={`font-semibold px-4 py-2 ${
            selectedButton === 'docs' ? 'text-orange-600' : 'text-zinc-700'
          }`}
          onClick={() => handleOpen('docs')}
        >
          Dokumentacja
        </button>
        <button
          className={`font-semibold px-4 py-2 ${
            selectedButton === 'notes' ? 'text-orange-600' : ' text-zinc-700'
          }`}
          onClick={() => handleOpen('notes')}
        >
          Notatki
        </button>
      </div>
      {selectedComponent}
    </nav>
  );
}
