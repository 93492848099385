/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AXIOS from '@/util/axios';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { ResetPasswordFormInput } from '@/types/types';

const ChangePasswordPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email') || '';
  const confirmationCode = queryParams.get('code') || '';

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<ResetPasswordFormInput>({
    mode: 'onChange',
  });

  const [message, setMessage] = useState<string>('');
  const [messageColor, setMessageColor] = useState<string>('text-red-600');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    number: false,
    lowercase: false,
    uppercase: false,
  });

  const password = watch('password');

  const validatePassword = (password: string) => {
    setPasswordValidation({
      length: password.length >= 6,
      number: /[0-9]/.test(password),
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
    });
  };

  useEffect(() => {
    if (password) {
      validatePassword(password);
    }
  }, [password]);

  const handleResetPassword: SubmitHandler<ResetPasswordFormInput> = async ({
    password,
    confirmPassword,
  }) => {
    if (password !== confirmPassword) {
      setMessage('Hasła nie są takie same.');
      setMessageColor('text-red-600');
      return;
    }

    try {
      const response = await AXIOS.post(
        '/api/users/password-reset-requests/use',
        {
          confirmationCode,
          email,
          plainPassword: password,
        }
      );
      if (response.status === 201) {
        setMessage('Hasło zostało zmienione pomyślnie.');
        setMessageColor('text-green-600');
        navigate('/user-actions/login');
      }
    } catch (error: any) {
      if (!error.response) {
        setMessage(
          'Wystąpił problem podczas zmiany hasła. Spróbuj ponownie później.'
        );
        setMessageColor('text-red-600');
        return;
      }

      const { status, data } = error.response || {};
      let errorMessage =
        data?.detail ||
        'Wystąpił problem podczas zmiany hasła. Spróbuj ponownie później.';

      if (status === 400) {
        switch (data?.detail) {
          case 'Wrong code or email.':
            errorMessage = 'Kod niepoprawny lub został już wykorzystany.';
            break;
          case 'User not found.':
            errorMessage = 'Nie ma takiego użytkownika.';
            break;
          case 'Password reset email has already been sent in the last 15 minutes.':
            errorMessage =
              'Email potwierdzający został już wysłany w ciągu 15 minut.';
            break;
          case 'User is not confirmed':
            errorMessage =
              'Użytkownik nie został potwierdzony. Musisz najpierw potwierdzić konto.';
            navigate('/user-actions/resend-email');
            break;
          default:
            errorMessage =
              'Nieprawidłowe dane. Sprawdź poprawność wprowadzonego adresu email.';
        }
      } else if (status === 422) {
        errorMessage = 'Wystąpiły błędy walidacji. Sprawdź poprawność danych.';
      }

      setMessage(errorMessage);
      setMessageColor('text-red-600');
    }
  };

  return (
    <div className='p-8 shadow-md rounded-md bg-white mx-4'>
      <h1 className='text-2xl text-gray-700 text-center'>Resetowanie hasła</h1>
      <form onSubmit={handleSubmit(handleResetPassword)} className='space-y-4'>
        <div>
          <label
            htmlFor='email'
            className='block text-sm font-medium text-gray-700 mb-1'
          >
            Adres Email
          </label>
          <input
            type='email'
            id='email'
            value={email}
            readOnly
            className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-gray-100'
          />
        </div>
        <div className='mb-4 relative'>
          <label
            htmlFor='password'
            className='block text-sm font-medium text-gray-700 mb-1'
          >
            Hasło
          </label>
          <div className='relative'>
            <input
              type={showPassword ? 'text' : 'password'}
              id='password'
              {...register('password', {
                required: 'Podaj hasło',
                minLength: {
                  value: 6,
                  message: 'Hasło musi mieć przynajmniej 6 znaków',
                },
              })}
              className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500'
            />
            <button
              type='button'
              onClick={() => setShowPassword((prev) => !prev)}
              className='absolute inset-y-0 right-0 px-3 text-gray-500'
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </button>
          </div>
          {errors.password && (
            <span className='text-red-500 text-xs'>
              {errors.password.message}
            </span>
          )}
          <ul className='flex flex-wrap text-xs my-2'>
            <li
              className={`my-1 w-1/2 ${
                passwordValidation.length ? 'text-green-600' : 'text-zinc-300'
              }`}
            >
              {passwordValidation.length ? '✔' : '✘'} Minimum 6 znaków
            </li>
            <li
              className={`my-1 w-1/2 ${
                passwordValidation.number ? 'text-green-600' : 'text-zinc-300'
              }`}
            >
              {passwordValidation.number ? '✔' : '✘'} Co najmniej jedna cyfra
            </li>
            <li
              className={`my-1 w-1/2 ${
                passwordValidation.lowercase
                  ? 'text-green-600'
                  : 'text-zinc-300'
              }`}
            >
              {passwordValidation.lowercase ? '✔' : '✘'} Co najmniej jedna mała
              litera
            </li>
            <li
              className={`my-1 w-1/2 ${
                passwordValidation.uppercase
                  ? 'text-green-600'
                  : 'text-zinc-300'
              }`}
            >
              {passwordValidation.uppercase ? '✔' : '✘'} Co najmniej jedna
              wielka litera
            </li>
          </ul>
        </div>
        <div className='mb-4 relative'>
          <label
            htmlFor='confirmPassword'
            className='block text-sm font-medium text-gray-700 mb-1'
          >
            Wpisz ponownie hasło
          </label>
          <div className='relative'>
            <input
              type={showPassword ? 'text' : 'password'}
              id='confirmPassword'
              {...register('confirmPassword', {
                required: 'Potwierdź hasło',
                validate: (value) =>
                  value === password || 'Hasła nie są takie same',
              })}
              className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500'
            />
            <button
              type='button'
              onClick={() => setShowPassword((prev) => !prev)}
              className='absolute inset-y-0 right-0 px-3 text-gray-500'
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </button>
          </div>
          {errors.confirmPassword && (
            <span className='text-red-500 text-xs'>
              {errors.confirmPassword.message}
            </span>
          )}
        </div>
        <div>
          <button
            type='submit'
            className='w-full px-4 py-2 bg-orange-500 text-white font-medium rounded-md shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500'
          >
            Zmień hasło
          </button>
        </div>
        {message && (
          <p className={`text-xs ${messageColor} text-center`}>{message}</p>
        )}
      </form>
    </div>
  );
};

export default ChangePasswordPage;
