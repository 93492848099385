import UserActionsForm from '../auth/UserActionForm';

export default function UserActionsPage() {
  return (
    <main className='flex-grow flex flex-col justify-center bg-white'>
      <main className='flex flex-col sm:flex-row justify-center items-center w-full gap-x-32'>
        <div className='md:flex hidden'>
          <img src='/loginimg.svg' alt='Login'/>
        </div>
        <UserActionsForm/>
      </main>
    </main>
  );
}
