import { useLocation, useNavigate } from 'react-router-dom';

import Register from './Register';
import Login from './Login';
import ResendEmailForm from './ResendEmailForm';
import EmailNotificationPage from '../pages/EmailNotificationPage';
import ResetPasswordForm from './ResetPasswordForm';

const UserActionsForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleFormSwitch = (formType: string) => {
    navigate(`/user-actions/${formType}`);
  };

  const handleResetPassword = () => {
    navigate(`/user-actions/reset-password`);
  };

  let currentForm;
  switch (true) {
    case location.pathname.includes('register'):
      currentForm = 'register';
      break;
    case location.pathname.includes('resend-email'):
      currentForm = 'resend-email';
      break;
    case location.pathname.includes('email-notification'):
      currentForm = 'email-notification';
      break;
    case location.pathname.includes('reset-password'):
      currentForm = 'reset-password';
      break;
    default:
      currentForm = 'login';
      break;
  }

  return (
    <div>
      {currentForm === 'login' && (
        <Login
          onFormSwitch={() => handleFormSwitch('register')}
          onResetPassword={() => handleResetPassword()}
        />
      )}
      {currentForm === 'register' && (
        <Register onFormSwitch={() => handleFormSwitch('login')} />
      )}
      {currentForm === 'resend-email' && (
        <ResendEmailForm
          message=''
          messenger='Wyślij ponownie email z kodem potwierdzającym rejestrację lub zaloguj się'
          onFormSwitch={() => handleFormSwitch('login')}
        />
      )}
      {currentForm === 'email-notification' && (
        <EmailNotificationPage
          message=''
          messenger='Podaj swój adres email, na który otrzymasz kod potwierdzający'
          onFormSwitch={() => handleFormSwitch('login')}
        />
      )}
      {currentForm === 'reset-password' && (
        <ResetPasswordForm
          message=''
          onFormSwitch={() => handleFormSwitch('login')}
          messenger='Podaj swój adres email, na który otrzymasz link resetujący hasło'
        />
      )}
    </div>
  );
};

export default UserActionsForm;
