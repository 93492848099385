/* eslint-disable @typescript-eslint/no-explicit-any */
import { useForm, SubmitHandler } from 'react-hook-form';
import { useState, useEffect, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { LockOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { useMutation } from '@tanstack/react-query';

import {
  RegisterFormInput,
  RegisterResponse,
  UserActionsProps,
} from '@/types/types';
import AXIOS from '@/util/axios';
import authReducer, { AuthState } from '@/util/AuthReducer';
import EmailNotificationPage from '../pages/EmailNotificationPage';

const Register = ({ onFormSwitch }: UserActionsProps) => {
  const navigate = useNavigate();
  const [registrationSuccess, setRegistrationSuccess] =
    useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>('');

  const [state, dispatch] = useReducer(authReducer, {
    status: 'idle',
    message: '',
  } as AuthState);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<RegisterFormInput>({
    defaultValues: {
      marketingConsent: false,
    },
  });

  const watchedEmail = watch('email', '');
  const password = watch('plainPassword') || '';

  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
  });

  useEffect(() => {
    const validatePassword = (password: string) => {
      const length = password.length >= 6;
      const uppercase = /[A-Z]/.test(password);
      const lowercase = /[a-z]/.test(password);
      const number = /\d/.test(password);

      setPasswordValidation({
        length,
        uppercase,
        lowercase,
        number,
      });
    };

    validatePassword(password);
  }, [password]);

  useEffect(() => {
    dispatch({ type: 'SET_EMAIL', email: watchedEmail });
  }, [watchedEmail]);

  const mutation = useMutation({
    mutationFn: async (data: RegisterFormInput): Promise<RegisterResponse> => {
      dispatch({ type: 'REQUEST' });
      const response = await AXIOS.post<RegisterResponse>(
        '/api/users/register',
        data
      );
      return response.data;
    },
    onSuccess: () => {
      dispatch({ type: 'SUCCESS', message: 'Registration successful!' });
      setMessage(
        'Witamy na pokładzie 😎 Pamiętaj, aby sprawdzić skrzynkę pocztową i potwierdzić rejestrację.'
      );
      setRegistrationSuccess(true);
      setTimeout(() => navigate('/user-actions/login'), 10000);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      let errorMessage = '';

      if (error.response) {
        if (
          error.response.data &&
          error.response.data.detail === 'User already exists.'
        ) {
          errorMessage = 'Użytkownik o podanym adresie email już istnieje. ';
        } else if (
          error.response.data.message === 'Validation errors' ||
          error.response.data.message === 'Bad request'
        ) {
          errorMessage = 'Użytkownik o podanym adresie email już istnieje.';
        }
      } else {
        errorMessage =
          'Wystąpił problem podczas rejestracji. Spróbuj ponownie później.';
      }

      dispatch({ type: 'ERROR', message: errorMessage });
    },
  });

  const handleRegister: SubmitHandler<RegisterFormInput> = async (data) => {
    if (data.plainPassword !== confirmPassword) {
      setMessage('Hasła nie są takie same.');
      return;
    }
    try {
      await mutation.mutateAsync({
        email: data.email,
        displayName: data.displayName,
        plainPassword: data.plainPassword,
        marketingConsent: data.marketingConsent ?? false,
      });
    } catch (error) {
      console.error('Error during registration:', error);
    }
  };

  return (
    <main className='flex flex-col sm:flex-row justify-center items-center bg-white rounded-md shadow-md py-12'>
      <div className='flex flex-col items-center mx-4 md:min-w-96'>
        {!registrationSuccess && (
          <>
            <div className='flex items-center justify-center w-10 h-10 bg-orange-500 rounded-full'>
              <LockOutlined className='text-white' />
            </div>
            <h2 className='text-2xl text-gray-700 text-center w-2/3'>
              Zarejestruj się i rozwijaj swoje umiejętności!
            </h2>
            <form onSubmit={handleSubmit(handleRegister)} className='w-full'>
              <div className='mb-4'>
                <label
                  htmlFor='displayName'
                  className='block text-sm font-medium text-gray-700 mb-1'
                >
                  Imię i Nazwisko
                </label>
                <input
                  type='text'
                  id='displayName'
                  {...register('displayName', {
                    required: 'Podaj imię i nazwisko',
                  })}
                  autoFocus
                  className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500'
                />
                {errors.displayName && (
                  <span className='text-red-500 text-xs'>
                    {errors.displayName.message}
                  </span>
                )}
              </div>
              <div className='mb-4'>
                <label
                  htmlFor='email'
                  className='block text-sm font-medium text-gray-700 mb-1'
                >
                  Adres Email
                </label>
                <input
                  type='email'
                  id='email'
                  {...register('email', { required: 'Podaj adres email' })}
                  className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500'
                />
                {errors.email && (
                  <span className='text-red-500 text-xs'>
                    {errors.email.message}
                  </span>
                )}
              </div>
              <div className='mb-4 relative'>
                <label
                  htmlFor='plainPassword'
                  className='block text-sm font-medium text-gray-700 mb-1'
                >
                  Hasło
                </label>
                <div className='flex justify-end items-center'>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id='plainPassword'
                    {...register('plainPassword', {
                      required: 'Podaj hasło',
                      minLength: {
                        value: 6,
                        message: 'Hasło musi mieć przynajmniej 6 znaków',
                      },
                    })}
                    className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500'
                  />
                  <button
                    type='button'
                    onClick={() => setShowPassword((prev) => !prev)}
                    className='absolute px-3 text-gray-500'
                    tabIndex={-1}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </button>
                </div>
                {errors.plainPassword && (
                  <span className='text-red-500 text-xs'>
                    {errors.plainPassword.message}
                  </span>
                )}
                <div className='relative'>
                <ul className='grid grid-rows-2 grid-cols-2 text-pretty text-xs my-2'>
                  <li
                    className={`my-1 ${
                      passwordValidation.length
                        ? 'text-green-600'
                        : 'text-zinc-300'
                    }`}
                  >
                    {passwordValidation.length ? '✔' : '✘'} Minimum 6 znaków
                  </li>
                  <li
                    className={`my-1 ${
                      passwordValidation.number
                        ? 'text-green-600'
                        : 'text-zinc-300'
                    }`}
                  >
                    {passwordValidation.number ? '✔' : '✘'} Co najmniej jedna
                    cyfra
                  </li>

                  <li
                    className={`my-1 ${
                      passwordValidation.lowercase
                        ? 'text-green-600'
                        : 'text-zinc-300'
                    }`}
                  >
                    {passwordValidation.lowercase ? '✔' : '✘'} Co najmniej jedna
                    mała litera
                  </li>
                  <li
                    className={`my-1 ${
                      passwordValidation.uppercase
                        ? 'text-green-600'
                        : 'text-zinc-300'
                    }`}
                  >
                    {passwordValidation.uppercase ? '✔' : '✘'} Co najmniej jedna
                    wielka litera
                  </li>
                </ul>
                </div>
              </div>
              <div className='mb-4 relative'>
                <label
                  htmlFor='confirmPassword'
                  className='block text-sm font-medium text-gray-700 mb-1'
                >
                  Wpisz ponownie hasło
                </label>
                <div className='flex justify-end items-center'>
                  <input
                    type={showPassword ? 'text' : 'password'}
                    id='confirmPassword'
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500'
                  />
                  <button
                    type='button'
                    onClick={() => setShowPassword((prev) => !prev)}
                    className='absolute px-3 text-gray-500'
                    tabIndex={-1}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </button>
                </div>
                {password !== confirmPassword && (
                  <span className='text-red-500 text-xs'>
                    Hasła nie są takie same
                  </span>
                )}
              </div>

              <div className='mt-12'>
                <div className='flex items-center'>
                  <input
                    type='checkbox'
                    id='marketingConsent'
                    {...register('marketingConsent')}
                    className='h-6 w-6 text-orange-600 focus:ring-orange-500'
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        e.currentTarget.click();
                      }
                    }}
                  />
                  <label
                    htmlFor='marketingConsent'
                    className='ml-2 text-sm font-medium text-pretty text-gray-700'
                  >
                    Wyrażam zgodę na otrzymywanie treści marketingowych
                  </label>
                </div>
              </div>

              <button
                type='submit'
                className='w-full px-4 py-2 mt-6 bg-orange-500 text-white font-medium rounded-md shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500'
              >
                Zarejestruj się
              </button>

              {state.status === 'error' && (
                <div className='text-red-500 text-xs'>{state.message}</div>
              )}
              <div className='text-center'>
                {state.email && (
                  <div className='mt-4 text-center'>
                    <button
                      onClick={() => navigate('/user-actions/resend-email')}
                      className='text-sm text-orange-500 hover:underline'
                    >
                      Wyślij ponownie email potwierdzający
                    </button>
                  </div>
                )}
              </div>
              <div className='mt-4 text-center'>
                <button
                  onClick={() => onFormSwitch()}
                  className='text-sm hover:underline'
                >
                  Masz już konto? Zaloguj się
                </button>
              </div>
            </form>
          </>
        )}
        {registrationSuccess && (
          <EmailNotificationPage
            message=''
            messenger={message}
            onFormSwitch={() => navigate('/user-actions/email-notification')}
          />
        )}
      </div>
    </main>
  );
};

export default Register;
