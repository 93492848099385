import { Link } from 'react-router-dom';

import { LinkProp } from '@/types/types';

export default function NavLink({
  href = '',
  title,
  className,
  onCommand,
  ...props
}: LinkProp) {
  return (
    <Link to={href} onClick={onCommand} className={className} {...props}>
      {title}
    </Link>
  );
}
