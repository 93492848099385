import { useState } from 'react';

import AXIOS from '@/util/axios';
import { NotificationFormProps } from '@/types/types';

const ResendEmailForm: React.FC<NotificationFormProps> = ({
  email,
  messenger,
  onFormSwitch,
}) => {
  const [message, setMessage] = useState<string>('');
  const [messageColor, setMessageColor] = useState<string>('text-red-600');

  const handleResend = async () => {
    try {
      const response = await AXIOS.post(
        `/api/users/register/resend-confirmation-mail/${email}`
      );
      if (response.status === 200) {
        setMessage(
          'Na Twoją pocztę email został wysłany ponownie mail potwierdzający konto.'
        );
        setMessageColor('text-red-600');
      }
      onFormSwitch();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      if (error.response.status === 400) {
        setMessage(
          'Na Twojej poczcie email znajduje się mail potwierdzający konto.'
        );
        setMessageColor('text-red-600');
      } else {
        setMessage(
          'Wystąpił problem podczas wysyłania maila. Spróbuj ponownie później.'
        );
        setMessageColor('text-red-600');
      }
    }
  };

  return (
    <div className='p-8 shadow-md rounded-md bg-white'>
      <h1 className='text-2xl text-gray-700 text-center'>
        Niepotwierdzone konto
      </h1>
      <p className='text-gray-700 mb-4 text-center'>{messenger}</p>
      <p className={`text-xs ${messageColor} text-center`}>{message}</p>
      <div className='flex items-center justify-center mt-4'>
        <button
          onClick={handleResend}
          className='px-2 py-2 bg-orange-500 text-white font-medium rounded-md shadow-sm hover:bg-orange-600'
        >
          Wyślij ponownie
        </button>
        <button
          onClick={onFormSwitch}
          className='ml-4 text-sm text-zinc-600 hover:underline'
        >
          Powrót do logowania
        </button>
      </div>
    </div>
  );
};

export default ResendEmailForm;
