import { Link } from 'react-router-dom';

const NotFound: React.FC = () => {
  return (
    <div className='flex flex-col items-center justify-start h-screen bg-white text-gray-900'>
      <div className='flex flex-col items-center justify-center'>
        <div className='md:w-1/2'>
          <img src='/404.webp' />
        </div>
        <p className='text-center text-sm mt-2 text-gray-600'>
          Wygląda na to, że strona, której szukasz, nie istnieje lub została
          przeniesiona.
        </p>
        <div className='mt-6'>
          <Link
            to='/'
            className='inline-block px-4 py-2 mx-2 text-white bg-orange-500 rounded hover:bg-orange-600'
          >
            Powrót do strony głównej
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
