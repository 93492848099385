import { useParams, useNavigate } from 'react-router-dom';
import AXIOS from '../../util/axios';
import Loading from '../../pages/Loading';
import { useQuery } from '@tanstack/react-query';
import { useCart } from '@/hooks/useCart';
import { useAuth } from '@/hooks/useAuth';
import { CartItem } from '@/types/types';
import FAQ from './FAQcourses';
import {useEffect} from "react";

export default function ShoppingPage() {
  const { courseId } = useParams<{ courseId: string }>();
  const navigate = useNavigate();
  const { data, error, isLoading } = useQuery({
    queryKey: ['courseData', courseId],
    queryFn: async () => {
      const response = await AXIOS.get(`/api/courses/courses/${courseId}`);
      return response.data;
    },
  });
  const { addToCart } = useCart();
  const { authState } = useAuth();

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  if (isLoading) return <Loading />;
  if (error) return <div>Error: {error.message}</div>;
  if (!data) return <div>No data available</div>;

  const course = data;

  const handleAddToCart = async () => {
    if (!authState?.token) {
      navigate('/user-actions/login', {
        state: {
          redirectTo: location.pathname,
          title: 'Zaloguj się',
          description: 'Musisz się zalogować, aby dodać kurs do koszyka.',
          variant: 'warning',
        },
      });
      return;
    }

    const cartItem: CartItem = {
      id: course.id,
      course: {
        id: course.id,
        name: course.name,
        priceFinal: course.priceFinal,
        priceRegular: course.regularPrice,
        thumbnailUrl: course.thumbnailUrl,
        description: course.description,
      },
      discountRate: course.discountRate,
      discountAmount: course.isDiscounted,
      name: course.name,
      priceFinal: course.priceFinal,
      priceRegular: course.regularPrice,
    };

    await addToCart(cartItem);
    navigate('/cart');
  };

  const handleShowPreview = async () => {
    if (!authState?.token) {
      navigate('/user-actions/login', {
        state: {
          redirectTo: `/courses/${courseId}/lesson/1`,
          title: 'Zaloguj się',
          description: 'Musisz się zalogować, aby wypróbować kurs.',
          variant: 'warning',
        },
      });
    } else {
      navigate(`/courses/${courseId}/lesson/1`);
    }
  };

  return (
    <main className="bg-[url('/mainbg.png')] bg-cover bg-center min-h-screen w-screen">
      <header className="bg-[url('/headerbg.webp')] bg-cover bg-center">
        <div className='grid grid-cols-1 md:grid-cols-3 gap-2 w-full sm:w-5/6'>
          <div className='col-span-1 md:col-span-2'>
            <aside key={course.id} className='block m-2 p-4 sm:p-10'>
              <div className='relative flex items-center justify-center max-w-1/3 cursor-default'>
                <img
                  src={course.thumbnailUrl}
                  alt={course.name}
                  className='w-full h-auto max-h-full object-cover'
                />
              </div>
            </aside>
          </div>

          <div className='col-span-1 flex flex-col justify-evenly items-center w-full sm:w-max p-4 sm:p-2'>
            <div className='w-full p-4'>
              <h2 className='text-4xl font-semibold mb-2 w-full'>
                <strong className='text-[#ff7a4e]'>{course.name}</strong> od
                podstaw
              </h2>

              <div className='text-2xl mb-4'>
                <p className='p-2'>
                  Cena regularna:{' '}
                  {course.isDiscounted ? (
                    <strong
                      style={{
                        textDecoration: 'line-through',
                        textDecorationColor: '#ff7a4e',
                      }}
                    >
                      {course.priceRegular}
                    </strong>
                  ) : (
                    <strong>{course.priceRegular}</strong>
                  )}
                </p>

                {course.isDiscounted && (
                  <p className='text-3xl p-2'>
                    Cena promocyjna:{' '}
                    <strong className='text-[#ff7a4e]'>
                      {course.discountedPrice}
                    </strong>
                  </p>
                )}

                <p className='text-sm text-zinc-500 p-2'>
                  Najniższa cena z ostatnich 30 dni: {data.omnibusLowestPrice}
                </p>
              </div>
            </div>
            <div className='flex justify-evenly items-center gap-4 m-4 font-semibold'>
              <button
                onClick={handleAddToCart}
                className='px-4 py-2 bg-[#ff7a4e] border-2 border-[#e95c2c] text-white text-sm rounded-full shadow-sm hover:bg-[#ee754d] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#ff7a4e]'
              >
                Dodaj do koszyka
              </button>
              <button
                onClick={handleShowPreview}
                className='px-4 py-2 bg-white border-2 border-[#e95c2c] text-zinc-700 text-sm rounded-full shadow-sm hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#ff7a4e]'
              >
                Wypróbuj kurs
              </button>
            </div>
          </div>
        </div>
      </header>
      <section className='flex flex-col items-start justify-center p-10'>
        <h1 className='text-4xl font-semibold'>
          Dlaczego warto wybrać ten{' '}
          <strong className='text-[#ff7a4e]'>{course.name}</strong>?
        </h1>
        <p>{course.htmlDescription}</p>
      </section>
      <FAQ />
    </main>
  );
}
