import AXIOS from './axios';

const fetchToken = async (email: string, password: string) => {
  const requestBody = {
    email: email,
    password: password,
  };

  const response = await AXIOS.post(
    '/api/security/token',
    requestBody,
    {
      headers: { 'Content-Type': 'application/json' },
    }
  );

  const responseData = response.data;

  const {
    token,
    refreshToken,
    refreshTokenExpiration,
    createdAt,
    expiredAt,
    user,
  } = responseData;

  return {
    token,
    refreshToken,
    refreshTokenExpiration,
    createdAt,
    expiredAt,
    user,
  };
};

export default fetchToken;
