import { useEffect, useReducer } from 'react';
import { useSearchParams } from 'react-router-dom';
import registrationReducer, {  RegistrationState } from '@/util/RegistrationReducer';

import AXIOS from '@/util/axios';

const RegistrationConfirmationPage: React.FC = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const [state, dispatch] = useReducer(registrationReducer, {
    status: 'loading',
    message: 'Loading...',
  } as RegistrationState);

  useEffect(() => {
    if (code) {
      dispatch({type: 'CONFIRM_REQUEST'});

      AXIOS.post(`/api/users/register/confirm/${code}`)
        .then(response => {
          if (response.status === 200) {
            dispatch({ type: 'CONFIRM_SUCCESS' });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 400) {
              dispatch({ type: 'ALREADY_CONFIRMED' });
            } else if (error.response.status === 404) {
              dispatch({ type: 'INVALID_CODE' });
            } else {
              dispatch({ type: 'CONFIRM_ERROR' });
            }
          } else {
            dispatch({ type: 'CONFIRM_ERROR' });
          }
        });
    } else {
      dispatch({ type: 'INVALID_CODE' });
    }
  }, [code]);

  return (
    <main className='flex flex-col items-center justify-start h-screen bg-white p-6'>
      <div className='w-56 md:w-72'>
        <img src='/mail.webp' alt='ikonka kopery' />
      </div>
      <h1 className='text-2xl font-semibold text-center'>
        Potwierdzenie konta
      </h1>
      <p
        className={
          state.status === 'success'
            ? 'text-green-600'
            : state.status === 'alreadyConfirmed'
            ? 'text-yellow-600'
            : 'text-red-600'
        }
      >
        {state.message}
      </p>
    </main>
  );
};

export default RegistrationConfirmationPage;
