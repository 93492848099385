import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import StepperComponent from '@/components/StepperComponent';
import { FormData, CartItem } from '@/types/types';
import AXIOS from '@/util/axios';
import NavLink from '@/components/navigation/NavLink';
import { useCart } from '@/hooks/useCart';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

const hardcodedPaymentMethods = [
  {
    id: 1,
    name: 'Karta kredytowa',
    imageUrl:
      'https://www.przelewy24.pl/themes/przelewy24/assets/img/metody-platnosci/glowne/karty.svg?26092022',
  },
  {
    id: 2,
    name: 'Przelewy24',
    imageUrl:
      'https://www.przelewy24.pl/themes/przelewy24/assets/img/base/przelewy24_logo_2022.svg',
  },
  {
    id: 3,
    name: 'GooglePay',
    imageUrl:
      'https://www.przelewy24.pl/themes/przelewy24/assets/img/metody-platnosci/glowne/googlepay.svg',
  },
  {
    id: 4,
    name: 'ApplePay',
    imageUrl: 'https://woocommerce.pl/wp-content/uploads/2021/06/pay-apple.png',
  },
  {
    id: 5,
    name: 'blik',
    imageUrl:
      'https://www.przelewy24.pl/themes/przelewy24/assets/img/metody-platnosci/glowne/blik.svg',
  },
];

const paymentMethodMapping: { [key: string]: string } = {
  'Karta kredytowa': 'credit_card',
  Przelewy24: 'przelewy24',
  GooglePay: 'googlepay',
  ApplePay: 'applepay',
  Blik: 'blik',
};

export default function OrderSummary() {
  const [formData, setFormData] = useState<FormData | null>(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false); // Add loading state
  const { items, totalPrice } = useCart();

  useEffect(() => {
    const orderData = localStorage.getItem('orderData');

    if (orderData) {
      const parsedOrderData = JSON.parse(orderData);
      setFormData(parsedOrderData);
      setSelectedPaymentMethod(parsedOrderData.selectedPaymentMethod || '');
    } else {
      console.error('No order data found in localStorage');
    }
  }, []);

  function getPaymentMethodForAPI(paymentMethodName: string): string {
    return paymentMethodMapping[paymentMethodName] || '';
  }

  async function passOrderDataToAPI() {
    if (!formData) {
      console.error('Brak danych klienta');
      return;
    }

    const paymentMethod = getPaymentMethodForAPI(selectedPaymentMethod);

    const orderPayload = {
      paymentMethod: paymentMethod,
      customerData: {
        type: formData.customerData.type,
        nip: formData.customerData.nip || '',
        name: formData.customerData.name,
        city: formData.customerData.city,
        postalCode: formData.customerData.postalCode,
        street: formData.customerData.street,
        houseNumber: formData.customerData.houseNumber,
        phoneNumber: formData.customerData.phoneNumber,
        emailAddress: formData.customerData.email,
      },
    };

    setIsLoading(true); // Start loading

    try {
      const response = await AXIOS.post(`/api/orders/orders`, orderPayload);

      if (response.data.paymentUrl) {
        window.location.href = response.data.paymentUrl;
      }
    } catch (error) {
      console.error('Błąd podczas przesyłania zamówienia:', error);
    } finally {
      setIsLoading(false); // End loading
    }
  }

  return (
    <main className='bg-white min-h-[100vh] h-full flex flex-col sm:flex-row overflow-hidden'>
      <section className='flex flex-col sm:flex-row w-full'>
        <aside className='flex flex-col w-full sm:w-2/3 p-10 sm:p-20'>
          <StepperComponent activeStep={2} />
          <Typography
            variant='h4'
            gutterBottom
            style={{ color: '#333', marginBottom: '20px', marginTop: '20px' }}
          >
            Wybrane produkty:
          </Typography>
          {items?.map((item: CartItem, index: number) => (
            <div
              key={item.course.id || index}
              className='mb-4 flex items-center'
            >
              <img
                src={item.course.thumbnailUrl}
                alt={item.course.name}
                className='w-1/4 object-cover mr-4'
              />
              <div className='flex-1'>
                <Typography variant='h6'>{item.course.name}</Typography>
              </div>
              <Typography
                variant='h6'
                style={{ color: '#5c5e5d', fontWeight: 'bold' }}
              >
                {item.priceFinal}
              </Typography>
            </div>
          ))}
          <hr style={{ border: '1px solid #f56c42', width: '100%' }} />
          <div className='flex justify-between items-center mt-4 pt-2'>
            <div className='flex justify-center items-center gap-2 uppercase'>
              <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                Kwota łączna
              </Typography>
              <Typography
                variant='caption'
                style={{
                  fontWeight: 'normal',
                  color: '#5c5e5d',
                  marginTop: '5px',
                }}
              >
                z VAT
              </Typography>
            </div>

            <div className='flex justify-end items-end'>
              <Typography
                variant='h6'
                style={{
                  color: '#5c5e5d',
                  fontWeight: 'bolder',
                  fontSize: '1.5em',
                }}
              >
                {totalPrice} zł
              </Typography>
            </div>
          </div>
        </aside>

        <aside className='flex flex-col justify-start w-full md:w-1/3 sm:w-2/3 bg-gray-100 p-14'>
          <Typography variant='h4' style={{ color: '#333', marginTop: '3em' }}>
            Dane odbiorcy:
          </Typography>
          <NavLink
  href=''
  title='EDYTUJ'
  className='mb-2 font-medium text-sm text-[#e6663b]'
  onCommand={(e) => {
    e.preventDefault(); // Prevent default navigation
    // Set the form data back to local storage before navigating
    if (formData) {
      localStorage.setItem('orderData', JSON.stringify(formData)); // Store form data in local storage
    }
    window.location.href = '/cart/checkout'; // Navigate to checkout form
  }}
/>


          {formData && (
            <div>
              <Typography>{formData.customerData.name}</Typography>
              <Typography>{formData.customerData.city}</Typography>
              <Typography>{formData.customerData.postalCode}</Typography>
              <Typography>{formData.customerData.street}</Typography>
              <Typography>{formData.customerData.houseNumber}</Typography>
              <Typography>{formData.customerData.phoneNumber}</Typography>
              <Typography>{formData.customerData.email}</Typography>
              {formData.customerData.type === 'company' && (
                <Typography>{formData.customerData.nip}</Typography>
              )}
            </div>
          )}

          <div className='flex justify-between items-center mt-6'>
            <Typography variant='h6'>Wybrana metoda płatności:</Typography>
            <div className='flex items-center'>
              {selectedPaymentMethod ? (
                hardcodedPaymentMethods
                  .filter((method) => method.name === selectedPaymentMethod)
                  .map((method) => (
                    <img
                      key={method.id}
                      src={method.imageUrl}
                      alt={method.name}
                      className='w-20 h-20 ml-2'
                    />
                  ))
              ) : (
                <Typography
                  variant='h6'
                  style={{ color: '#5c5e5d', fontWeight: 'bolder' }}
                >
                  Nie wybrano metody płatności
                </Typography>
              )}
            </div>
          </div>

          <div className='flex items-center justify-between w-full mt-4 text-sm'>
            <NavLink
              href='/cart/checkout'
              onCommand={passOrderDataToAPI}
              title='Wróć'
              className='px-4 py-2 bg-[#ff7a4e] text-white font-medium text-sm rounded-md shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#ff7a4e]'
            />
            <NavLink
              href=''
              onCommand={passOrderDataToAPI}
              title={
                isLoading ? (
                  <Stack direction='row' spacing={1} alignItems='center'>
                    <CircularProgress size={20} color='inherit' />
                  </Stack>
                ) : (
                  'Kupuję i płacę'
                )
              }
              className='px-4 py-2 bg-[#ff7a4e] text-white font-medium text-sm rounded-md shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#ff7a4e]'
              disabled={isLoading}
            />
          </div>
        </aside>
      </section>
    </main>
  );
}
