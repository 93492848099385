import {CourseCardProps} from "@/types/types.ts";
import {Card, CardDescription, CardFooter, CardHeader, CardImage, CardTitle} from "@components/ui/card.tsx";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {useNavigate} from "react-router-dom";

export default function CourseCard({id, name, description, thumbnailUrl, price, regularPrice, available}: CourseCardProps) {
  const navigate = useNavigate();

  const handleCartClick = (courseId: number) => {
    if (!available) return;

    navigate(`/courses/course-details/${courseId}`);
  };

  return (
    <Card
      className={
        `rounded-2xl transition-transform ease-in-out duration-200 relative 
        ${!available ? 'opacity-50 cursor-not-allowed' : 'hover:scale-95 hover:shadow-lg cursor-pointer'}
      `}
      onClick={() => handleCartClick(id)}
    >
      {!available &&
        <div
          className='absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg text-white text-xl font-semibold'>
          Wkrótce dostępne
        </div>
      }
      <div className='px-3 pt-3'>
        <CardImage src={thumbnailUrl} className='rounded-md'/>
      </div>
      <CardHeader>
        <CardTitle className='py-0 ml-[-2px] ml text-xl'>{name}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      {available ?
      <CardFooter>
        <div className='flex w-full justify-between items-center mt-2'>
          <div className="flex flex-col">
            <p className="text-lg font-semibold text-zinc-800 leading-none">
              {regularPrice == null ?
                <span>{price}</span> :
                <span className='font-bold'>{price}
                  <span className="text-sm line-through text-gray-500 pl-2">{regularPrice}</span>
                </span>
              }
            </p>
            <span className="text-xs">brutto</span>
          </div>
          <ShoppingCartIcon className='cursor-pointer'/>
        </div>
      </CardFooter> : <div className='sm:pt-9'></div>
      }
    </Card>
  )
}
