const DataDeletionInstructions: React.FC = () => {
  return (
    <div className='container mx-auto px-20 py-8 text-zinc-700'>
      <h1 className='text-2xl font-bold mb-4 uppercase'>
        Instrukcja wycofania zgody na przetwarzanie danych osobowych
      </h1>

      <h2 className='text-xl font-semibold mt-6'>I. Cel instrukcji</h2>
      <p>
        Niniejsza instrukcja ma na celu określenie zasad usuwania danych
        osobowych zgodnie z Rozporządzeniem o Ochronie Danych Osobowych (RODO).
        W szczególności dotyczy realizacji prawa do bycia zapomnianym (art. 17
        RODO), które uprawnia osoby fizyczne do żądania usunięcia ich danych
        osobowych.
      </p>

      <h2 className='text-xl font-semibold mt-6'>
        II. Podstawy prawne usunięcia danych osobowych
      </h2>
      <p className='mb-2'>
        Osoby, których dane dotyczą, mogą żądać ich usunięcia w następujących
        przypadkach:
      </p>
      <ol className='list-inside ml-4' style={{ listStyleType: 'lower-alpha' }}>
        <li>
          Dane osobowe nie są już niezbędne do celów, w których zostały zebrane
          lub przetwarzane.
        </li>
        <li>
          Osoba, której dane dotyczą, cofa zgodę na przetwarzanie, a nie ma
          innej podstawy prawnej do ich przetwarzania.
        </li>
        <li>
          Osoba, której dane dotyczą, wnosi sprzeciw wobec przetwarzania danych
          i nie występują nadrzędne prawnie uzasadnione podstawy przetwarzania.
        </li>
        <li>Dane osobowe były przetwarzane niezgodnie z prawem.</li>
        <li>
          Dane osobowe muszą zostać usunięte w celu wypełnienia obowiązku
          prawnego wynikającego z prawa Unii lub prawa państwa członkowskiego.
        </li>
      </ol>

      <h2 className='text-xl font-semibold mt-6'>
        III. Jak zgłosić żądanie usunięcia danych osobowych
      </h2>
      <ol className='list-decimal list-inside ml-4'>
        <li>
          Zgłoszenie wniosku:
          <ol
            className='list-inside ml-4'
            style={{ listStyleType: 'lower-alpha' }}
          >
            <li>
              Osoba, której dane dotyczą, może złożyć wniosek o usunięcie danych
              poprzez wiadomość e-mail na adres:{' '}
              <a
                href='mailto:kontakt@pfaff.app'
                className='text-orange-500 underline'
              >
                kontakt@pfaff.app
              </a>
            </li>
            <li>
              Wniosek powinien zawierać wystarczające dane umożliwiające
              identyfikację osoby, której dane dotyczą, oraz określenie zakresu
              danych, które mają zostać usunięte.
            </li>
            <li>
              Wniosek powinien zostać złożony z tego samego adresu e-mail, który
              był podawany przy zgodzie na przetwarzanie danych osobowych.
            </li>
          </ol>
        </li>
        <li>
          Po pozytywnym rozpatrzeniu wniosku dane osoby wnioskującej zostaną
          niezwłocznie usunięte z naszych baz danych oraz wszyscy współpracujący
          podwykonawcy zostaną poinformowani o wystąpieniu takiego żądania.
        </li>
        <li>
          Administrator może negatywnie rozpatrzyć wniosek, jeżeli nie będzie w
          stanie zweryfikować tożsamości osoby. Osoba ta zostanie niezwłocznie
          poinformowana o odrzuceniu wniosku wraz z wskazaniem przyczyny takiej
          decyzji.
        </li>
      </ol>

      <h2 className='text-xl font-semibold mt-6'>
        IV. Aktualizacja instrukcji
      </h2>
      <p>
        Niniejsza instrukcja może być aktualizowana w celu dostosowania do
        zmieniających się przepisów prawnych, technologii oraz procesów
        wewnętrznych. Użytkownik serwisu zostanie poinformowany o każdorazowej
        zmianie niniejszej instrukcji.
      </p>
    </div>
  );
};

export default DataDeletionInstructions;
