interface CircularProgressProps {
  value: number
  max: number
  size?: 'md'
  centerContent?: React.ReactNode
}

export default function CircularProgress({
                                           value,
                                           max,
                                           size = 'md',
                                           centerContent = value,
                                         }: CircularProgressProps) {
  const segments = Array.from({ length: max }, (_, i) => i)
  const filledSegments = Math.min(value, max)

  const sizeValues = {
    md: 48,
  }

  const sizeClasses = {
    md: 'w-12 h-12',
  }

  const centerSizeClasses = {
    md: 'text-3xl',
  }

  const svgSize = sizeValues[size]
  const radius = svgSize / 2
  const strokeWidth = svgSize / 10

  const createArc = (startAngle: number, endAngle: number) => {
    const start = polarToCartesian(radius, radius, radius - strokeWidth / 2, endAngle)
    const end = polarToCartesian(radius, radius, radius - strokeWidth / 2, startAngle)
    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1"
    return [
      "M", start.x, start.y,
      "A", radius - strokeWidth / 2, radius - strokeWidth / 3, 3, largeArcFlag, 0, end.x, end.y
    ].join(" ")
  }

  const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0
    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    }
  }

  return (
    <div className={`relative ${sizeClasses[size]}`} role="progressbar" aria-valuenow={value} aria-valuemin={0} aria-valuemax={max}>
      <svg width={svgSize} height={svgSize} viewBox={`0 0 ${svgSize} ${svgSize}`}>
        {segments.map((_, index) => {
          const startAngle = (index / max) * 360
          const endAngle = ((index + 0.90) / max) * 360 // 0.95 to create a small gap between segments
          return (
            <path
              key={index}
              d={createArc(startAngle, endAngle)}
              fill="none"
              stroke={index < filledSegments ? '#fc6e3e' : 'rgba(0, 0, 0, 0.1)'}
              strokeWidth={strokeWidth}
            />
          )
        })}
      </svg>
      <div className="absolute inset-0 flex items-center justify-center">
        <span className={`font-bold ${centerSizeClasses[size]}`}>
          {centerContent}
        </span>
      </div>
    </div>
  )
}
