/* eslint-disable @typescript-eslint/no-explicit-any */
import { useLocation, useNavigate } from 'react-router-dom';
import { LockOutlined, Visibility, VisibilityOff } from '@mui/icons-material';
import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useEffect, useReducer, useState } from 'react';

import { useAuth } from '@/hooks/useAuth';
import {
  LoginFormInput,
  LoginVariables,
  UserActionsProps,
} from '@/types/types';
import Loading from '@/pages/Loading';
import authReducer, { AuthState } from '@/util/AuthReducer';
import ResendEmailForm from './ResendEmailForm';
import { Toaster } from '@/components/ui/toaster';
import { useToast } from '@/components/ui/use-toast';
import { API } from '@/util/axios';

export default function Login({ onFormSwitch }: UserActionsProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<LoginFormInput>();

  const email = watch('email') || '';
  const navigate = useNavigate();
  const { login } = useAuth();
  const location = useLocation();
  const { toast } = useToast();
  const [showResendEmail, setShowResendEmail] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [state, dispatch] = useReducer(authReducer, {
    status: 'idle',
    message: '',
  } as AuthState);

  useEffect(() => {
    if (location.state?.title) {
      toast({
        title: location.state.title,
        description: location.state.description,
        variant: location.state.variant,
      });
    }
  }, [location, toast]);

  const mutation: UseMutationResult<void, Error, LoginVariables> = useMutation({
    mutationFn: async (variables: LoginVariables) => {
      const { email, password } = variables;
      dispatch({ type: 'REQUEST' });
      await login(email, password);
    },
    onSuccess: () => {
      dispatch({ type: 'SUCCESS', message: 'Logged in successfully!' });
      const redirectTo = location.state?.redirectTo || '/';
      navigate(redirectTo);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onError: (error: any) => {
      let errorMessage = '';
      if (error.response) {
        switch (error.response.data.message) {
          case 'Account is disabled.':
            errorMessage =
              'Konto nieaktywne. Skontaktuj się z pomocą techniczną - tuto@pfaff.app';
            break;
          case 'Account is unconfirmed.':
            setMessage(
              'Przed zalogowaniem musisz potwierdzić swoje konto, sprawdź swoją skrzynkę pocztową lub wyślij ponownie email z kodem potwierdzającym rejestrację'
            );
            setShowResendEmail(true);
            break;
          case 'Invalid credentials.':
            errorMessage = 'Niepoprawne dane logowania.';
            break;
          default:
            errorMessage = 'Wystąpił nieoczekiwany błąd.';
            break;
        }
      }
      dispatch({ type: 'ERROR', message: errorMessage });
    },
  });

  const handleLogin: SubmitHandler<LoginFormInput> = ({ email, password }) => {
    mutation.mutate({ email, password });
  };

  const handleResetPassword = () => {
    navigate('/user-actions/reset-password');
  };
  const handleOAuthLogin = async (provider: 'google' | 'facebook') => {
    window.location.href = `${API}/api/security/oauth/${provider}`;
  };

  if (mutation.isPending) {
    return <Loading />;
  }

  if (showResendEmail) {
    return (
      <ResendEmailForm
        message=''
        email={email}
        messenger={message}
        onFormSwitch={() => {
          setShowResendEmail(false);
          navigate('/user-actions/login');
        }}
      />
    );
  }

  return (
    <div>
      <div className='flex flex-col items-center p-8 shadow-md bg-white rounded-md md:min-w-96'>
        <div className='flex items-center justify-center w-10 h-10 bg-orange-500 rounded-full mb-4'>
          <LockOutlined className='text-white' />
        </div>
        <h2 className='text-2xl mb-4 text-gray-700'>Zaloguj się</h2>
        <form onSubmit={handleSubmit(handleLogin)} className='w-full'>
          <div className='mb-4'>
            <label
              htmlFor='email'
              className='block text-sm font-medium text-gray-700 mb-1'
            >
              Adres Email
            </label>
            <input
              type='email'
              id='email'
              value={email}
              {...register('email', { required: 'Podaj adres email' })}
              autoFocus
              className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500'
            />
            {errors.email && (
              <span className='text-red-500 text-xs'>
                {errors.email.message}
              </span>
            )}
          </div>
          <div className='mb-4 relative'>
            <label
              htmlFor='password'
              className='block text-sm font-medium text-gray-700 mb-1'
            >
              Hasło
            </label>
            <input
              type={showPassword ? 'text' : 'password'}
              id='password'
              {...register('password', { required: 'Podaj hasło' })}
              className='w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500 pr-10'
            />
            <button
              type='button'
              onClick={() => setShowPassword((prev) => !prev)}
              className='absolute right-3 bottom-2 text-gray-500 focus:outline-none'
              tabIndex={-1}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </button>
            {errors.password && (
              <span className='text-red-500 text-xs'>
                {errors.password.message}
              </span>
            )}
          </div>

          <button
            type='submit'
            className='w-full px-4 py-2 my-4 bg-orange-500 text-white font-medium rounded-md shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-orange-500'
          >
            Zaloguj się
          </button>
          {state.status === 'error' && (
            <div className='text-red-500 text-xs'>{state.message}</div>
          )}
          <div className='text-center'>
            <button
              type='button'
              onClick={handleResetPassword}
              className='text-sm hover:underline'
            >
              Nie pamiętam hasła
            </button>
          </div>
          <div className='flex items-center justify-center'>
            <img
              src='/Google.svg'
              alt='Google'
              className='cursor-pointer mr-2'
              onClick={() => handleOAuthLogin('google')}
            />
            <img
              src='/Facebook.svg'
              alt='Facebook'
              className='cursor-pointer'
              onClick={() => handleOAuthLogin('facebook')}
            />
          </div>

          <div className='text-center'>
            <button
              onClick={() => onFormSwitch()}
              className='text-sm hover:underline'
            >
              Nie masz konta? Zarejestruj się
            </button>
          </div>
        </form>
      </div>
      <Toaster />
    </div>
  );
}
