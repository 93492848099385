import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';

import logo from '/logonew.webp';
import { url } from '@/components/lessons/url';
import Title from '../lesson/Title';
import NavLink from './NavLink';
import { useAuth } from '@/hooks/useAuth';
import { ShoppingBasket } from 'lucide-react';
import { useCart } from '@/hooks/useCart';

export default function Navigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const { itemCount } = useCart();
  const { authState, logout } = useAuth();
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const links = [];

  if (authState) {
    links.push({ href: '/settings', title: 'Ustawienia', icon: SettingsIcon });
  }

  const handleLogout = () => {
    setDropdownVisible(false);
    logout(() => navigate('/'));
  };

  const handleNavigation = (href: string) => {
    setDropdownVisible(false);
    navigate(href);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setDropdownVisible(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <nav className='flex justify-between items-center bg-slate-100 font-semibold py-2 px-4'>
      <div className='flex items-center justify-center'>
        <Link to='/'>
          <img src={logo} alt='Home' className='h-12 w-auto' />
        </Link>
        {location.pathname === '/lesson' && (
          <Title lessonTitle={url[0].title} />
        )}
      </div>
      <div className='flex justify-center items-center'>
        <NavLink
          href='/courses'
          title='Kursy'
          className='transition-opacity duration-300 ease-in-out hover:opacity-75 mr-4'
        />

        {authState?.user ? (
          <>
            <div className='relative' ref={dropdownRef}>
              <div
                className='flex items-center justify-center cursor-pointer rounded-md py-1 px-2 my-1 bg-gray-200 hover:bg-gray-300 transition-colors duration-200'
                onClick={() => setDropdownVisible(!dropdownVisible)}
              >
                <Avatar
                  alt='Zdjęcie użytkownika'
                  src={authState?.user.avatarUrl}
                  className='mr-1'
                  sx={{ width: 32, height: 32 }}
                />
                <p className='ml-1'>{authState?.user.displayName}</p>
              </div>
              {dropdownVisible && (
                <div className='absolute right-0 mt-2 w-full bg-white border border-gray-300 rounded-md shadow-lg z-40 text-sm transition duration-200 ease-in-out transform scale-100 origin-top'>
                  <ul className='py-1 text-zinc-700'>
                    {links.map((link) => (
                      <li
                        key={link.href}
                        className='flex justify-evenly items-center px-2 py-2 hover:bg-gray-100 cursor-pointer transition-all duration-200 hover:opacity-75'
                        onClick={() => handleNavigation(link.href)}
                      >
                        <link.icon className='mr-2' fontSize='small' />
                        <span className='flex-grow block'>{link.title}</span>
                      </li>
                    ))}
                    <li
                      className='flex justify-evenly items-center px-2 py-2 hover:bg-gray-100 cursor-pointer transition-all duration-200 hover:opacity-75'
                      onClick={handleLogout}
                    >
                      <LogoutIcon
                        className='text-[#ff7a4e] mr-2'
                        fontSize='small'
                      />
                      <span className='w-full text-left text-[#ff7a4e]'>
                        Wyloguj się
                      </span>
                    </li>
                  </ul>
                </div>
              )}
            </div>
            {itemCount > 0 && (
              <Link to='/cart' className='relative'>
                <div className='flex items-center justify-center cursor-pointer rounded-md py-2 px-2 my-1 ml-2 bg-orange-300 hover:bg-orange-400 transition-colors duration-200'>
                  <ShoppingBasket className='text-white' />
                  <span className='absolute top-0 right-0 bg-orange-500 text-white rounded-full text-xs w-5 h-5 flex items-center justify-center'>
                    {itemCount}
                  </span>
                </div>
              </Link>
            )}
          </>
        ) : (
          <div className='flex items-center'>
            <ul className='flex'>
              {links.map((link) => (
                <li key={link.href}>
                  <NavLink href={link.href} title={link.title} />
                </li>
              ))}
            </ul>
            <NavLink
              href='/user-actions/login'
              title='Zaloguj się'
              className='px-4 py-2 bg-[#ff7a4e] text-white font-medium text-sm rounded-md shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#ff7a4e] transition-colors duration-200 ease-in-out'
            />
          </div>
        )}
      </div>
    </nav>
  );
}
