import { useState, ReactNode } from 'react';

import LessonContext from './LessonIdCtx';

export const LessonProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [currentLessonId, setCurrentLessonId] = useState<number>(1);

  return (
    <LessonContext.Provider value={{ currentLessonId, setCurrentLessonId }}>
      {children}
    </LessonContext.Provider>
  );
};
