import { useContext } from 'react';

import LessonContext from '@/context/LessonIdCtx';
import { LessonContextType } from '@/types/types';

export const useLesson = (): LessonContextType => {
  const context = useContext(LessonContext);
  if (!context) {
    throw new Error('useLesson must be used within a LessonProvider');
  }
  return context;
};
