/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import AXIOS from '@/util/axios';
import { NotificationFormProps } from '@/types/types';
import { useNavigate } from 'react-router-dom';

const ResetPasswordForm: React.FC<NotificationFormProps> = ({
  email: initialEmail = '',
  messenger,
  onFormSwitch,
}) => {
  const [message, setMessage] = useState<string>('');
  const [email, setEmail] = useState<string>(initialEmail);
  const [messageColor, setMessageColor] = useState<string>('text-red-600');
  const navigate = useNavigate();

  const handleResend = async () => {
    try {
      const response = await AXIOS.post(`/api/users/password-reset-requests`, {
        email: email,
      });
      if (response.status === 201) {
        setMessage(
          'Na Twoją pocztę został wysłany email do zresetowania hasła.'
        );
        setMessageColor('text-green-600');
        onFormSwitch();
      }
    } catch (error: any) {
      const { status, data } = error.response || {};

      let errorMessage =
        'Wystąpił problem podczas zmiany hasła. Spróbuj ponownie później.';

      if (!error.response) {
        errorMessage =
          'Wystąpił problem podczas wysyłania maila. Spróbuj ponownie później.';
      } else if (status === 400) {
        switch (data?.detail) {
          case 'User cannot reset password when using OAuth authentication.':
            errorMessage =
              'Użytkownik nie może zresetować hasła, gdy jest zalogowany poprzez OAuth (Google lub Facebook).';
            break;
          case 'User not found.':
            errorMessage = 'Nie ma takiego użytkownika.';
            break;
          case 'Password reset email has already been sent in the last 15 minutes.':
            errorMessage =
              'Email potwierdzający został już wysłany w ciągu 15 minut.';
            break;
          case 'User is not confirmed':
            errorMessage =
              'Użytkownik nie został potwierdzony. Musisz najpierw potwierdzić konto.';
            navigate('/user-actions/resend-email');
            break;
          default:
            errorMessage =
              'Nieprawidłowe dane. Sprawdź poprawność wprowadzonego adresu email.';
        }
      }

      setMessage(errorMessage);
      setMessageColor('text-red-600');
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  return (
    <div className='p-8 shadow-md rounded-md bg-white'>
      <h1 className='text-2xl text-gray-700 text-center'>Resetowanie hasła</h1>
      <p className='text-gray-700 mb-4 text-center'>{messenger}</p>
      <p className={`text-xs ${messageColor} text-center`}>{message}</p>
      <div className='flex flex-col items-center justify-center mt-4'>
        <input
          type='email'
          value={email}
          onChange={handleEmailChange}
          placeholder='Wprowadź swój adres email'
          className='w-full mb-4 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-orange-500 focus:border-orange-500'
        />
        <div className='flex items-center justify-center mt-4'>
          <button
            onClick={handleResend}
            className='px-2 py-2 bg-orange-500 text-white font-medium rounded-md shadow-sm hover:bg-orange-600'
          >
            Wyślij ponownie
          </button>
          <button
            onClick={onFormSwitch}
            className='ml-4 text-sm text-zinc-600 hover:underline'
          >
            Powrót do logowania
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
