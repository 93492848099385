import { useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';

import { Chapter, Lesson } from '@/types/types';
import AccordionComponent from './AccordionComponent';
import { useLesson } from '@/hooks/useLesson';
import AXIOS from '@/util/axios';
import Loading from '@/pages/Loading';
import { formatVideoTime } from '@/util/TimeConverter';


export default function LessonMenu() {
  const { setCurrentLessonId } = useLesson();
  const { courseId } = useParams();
  const navigate = useNavigate();

  const {
    data: chaptersData,
    error,
    isLoading,
  } = useQuery({
    queryKey: ['chapters'],
    queryFn: async () => {
      const { data } = await AXIOS.get(
        `/api/courses/chapters?sort[orderId]=asc&filters[course][type]=equal&filters[course][value]=${courseId}`
      );
      return data;
    },
  });

  if (isLoading) return <Loading />;
  if (error) return <div>Error: {error.message}</div>;

  const chapters = chaptersData?._embedded?.items ?? [];

  const showNextLesson = (lessonId: number, accessible: boolean) => {
    if (accessible) {
      setCurrentLessonId(lessonId);
      navigate(`/courses/${courseId}/lesson/${lessonId}`, { replace: true }); 
    } else {
      navigate(`/courses/course-details/${courseId}`);
    }
  };

  return (
    <section className='h-full overflow-y-auto w-full'>
      {chapters.map((chapter: Chapter) => (
        <AccordionComponent
          key={chapter.id}
          title={chapter.name}
          content={
            <div>
              <ul>
                {chapter.lessons.map((lesson: Lesson) => (
                  <li
                    key={lesson.id}
                    className={`flex justify-between items-center py-6 border-t transition-all duration-300 ease-in-out ${
                      lesson.accessible
                        ? 'hover:bg-slate-200 cursor-pointer'
                        : 'bg-gray-100 hover:bg-gray-200 text-gray-400 cursor-pointer'
                    }`}
                    onClick={() => showNextLesson(lesson.id, lesson.accessible)}
                  >
                    <div className='flex justify-between items-center w-full px-2'>
                      <h5 className='text-sm text-zinc-700'>{lesson.name}</h5>
                      <div className=''>
                        {lesson.accessible ? (
                          <div className='flex justify-center items-center'>
                            <OndemandVideoIcon
                              fontSize='small'
                              className='mr-2'
                            />
                            <h5>{formatVideoTime(lesson.videoTimeSeconds)}</h5>
                          </div>
                        ) : (
                          <ShoppingCartCheckoutIcon fontSize='small' />
                        )}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          }
        />
      ))}
    </section>
  );
}
