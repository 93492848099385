import Quill from 'quill';
import { useEffect, useRef, useState } from 'react';
import 'quill/dist/quill.snow.css';

export default function Notes() {
  const [quill, setQuill] = useState<Quill | null>(null);
  const editorRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (editorRef.current !== null && quill === null) {
      const editor = document.createElement('div');
      editorRef.current.innerHTML = '';
      editorRef.current.append(editor);
      const q = new Quill(editor, {
        theme: 'snow',
      });
      setQuill(q);
    }

    return () => {
      if (quill) {
        quill.off('text-change');
      }
    };
  }, [editorRef, quill]);

  return (
    <section className='max-w-3xl mx-auto p-6 my-6 bg-white rounded-lg shadow-md'>
      <div className='border-gray-300 pb-4 mb-6'>
        <h2 className='text-2xl text-[#003366] text-center'>Notatki</h2>
      </div>
      <div ref={editorRef} className='max-h-screen rounded-md bg-white text-gray-800'></div>
    </section>
  );
}
