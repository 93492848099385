import CookieBanner from '@/components/CookieBanner';
import Footer from '@/components/footer/Footer';
import Navigation from '@/components/navigation/Navigation';
import { Children } from '@/types/types';

const Layout = ({ children }: Children) => {
  return (
    <div className='flex flex-col min-h-screen overflow-x-hidden font-lexend'>
      <Navigation />
      <main className='flex-grow flex flex-col justify-center'>
        {children}
      </main>
      <Footer />
      <CookieBanner />
    </div>
  );
};

export default Layout;
