import {CourseCardProgressProps} from "@/types/types.ts";
import {useNavigate} from "react-router-dom";
import {Card, CardDescription, CardFooter, CardHeader, CardImage, CardTitle} from "@components/ui/card.tsx";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CircularProgress from "@components/courses/CircularProgress.tsx";

export default function CourseCardProgress({
                                             id,
                                             name,
                                             description,
                                             thumbnailUrl,
                                             currentChapter,
                                             totalChapters,
                                             currentLesson,
                                             currentLessonInChapter,
                                             totalLessonsInChapter
                                           }: CourseCardProgressProps) {
  const navigate = useNavigate();

  const handleCartClick = (courseId: number) => {
    navigate(`/courses/${courseId}/lesson/${currentLesson}`);
  };

  return (
    <Card
      className='rounded-2xl transition-transform ease-in-out duration-200 relative hover:scale-95 hover:shadow-lg cursor-pointer'
      onClick={() => handleCartClick(id)}
    >
      <div className='px-3 pt-3'>
        <CardImage src={thumbnailUrl} className='rounded-md'/>
      </div>
      <CardHeader>
        <CardTitle className='py-0 ml-[-2px] ml text-xl'>{name}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardFooter>
        <div className='flex w-full justify-between items-center mt-2'>
          <div className='flex flex-col'>
            <p className='text-sm text-zinc-600 leading-none'>Rozdział</p>
            <p className='flex items-baseline space-x-2'>
              <span className='text-3xl font-medium'>{currentChapter}</span>
              <span className='text-xl text-zinc-500'>/ {totalChapters}</span>
            </p>
          </div>
          <div className='p-b-2'>
            <CircularProgress max={totalLessonsInChapter} value={currentLessonInChapter} centerContent={<div className='pb-2 text-zinc-700'><PlayArrowIcon/></div>}/>
          </div>
        </div>
      </CardFooter>
    </Card>
  )
}