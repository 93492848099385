import { createContext, useState, useCallback, useEffect } from 'react';
import { useAuth } from '@/hooks/useAuth';
import { CartContextProps, CartItem } from '@/types/types';
import AXIOS from '@/util/axios';
import fetchToken from '@/util/FetchToken';

export const CartContext = createContext<CartContextProps | undefined>(
  undefined
);

export const CartProvider = ({ children }: { children: React.ReactNode }) => {
  const [items, setItems] = useState<CartItem[] | null>(() => {
    const savedCart = localStorage.getItem('cartItems');
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const [discountCode, setDiscountCode] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { authState } = useAuth();

  const fetchCart = useCallback(async () => {
    const cartId = authState?.user?.cart?.id;
    if (authState?.token && cartId) {
      try {
        const response = await AXIOS.get(`/api/carts/carts/${cartId}`, {
          headers: {
            Authorization: `Bearer ${authState.token}`,
            'Content-Type': 'application/json',
          },
        });
        const fetchedCart = response.data;
        setItems(fetchedCart.items);
        setDiscountCode(fetchedCart.discountCode);

        localStorage.setItem('cartItems', JSON.stringify(fetchedCart.items));
      } catch (error) {
        console.error('Error fetching cart:', error);
      }
    } else {
      console.error('No token or cart ID available');
    }
  }, [authState]);

  useEffect(() => {
    if (authState?.user && authState.token) {
      fetchCart();
    }
  }, [authState, fetchCart]);

  const updateCart = async (
    cartItems: CartItem[],
    discountCode: string | null
  ) => {
    const cartId = authState?.user?.cart?.id;
    if (authState?.user?.email && authState?.password && cartId) {
      try {
        await fetchToken(authState.user.email, authState.password);
        const itemsPayload = cartItems.map((item) => ({
          priceRegular: item.priceRegular,
          discountRate: item.discountRate,
          discountAmount: item.discountAmount,
          priceFinal: item.priceFinal,
          course: {
            id: item.course.id,
            name: item.course.name,
            description: item.course.description,
            thumbnailUrl: item.course.thumbnailUrl,
          },
        }));

        const totalPayload = {
          items: itemsPayload,
          discountCode: discountCode ? { id: discountCode } : null,
        };

        const response = await AXIOS.patch(
          `/api/carts/carts/${cartId}`,
          totalPayload
        );

        setItems(response.data.items || []);
        setDiscountCode(response.data.discountCode?.id || null);
        setErrorMessage(null);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        if (error.response && error.response.status === 422) {
          setErrorMessage('Invalid discount code.');
        } else if (error.response && error.response.status === 500) {
          setErrorMessage('Server error. Please try again later.');
        } else {
          setErrorMessage('Error updating cart.');
        }
      }
    } else {
      console.error('No cart ID available');
    }
  };

  const addToCart = async (item: CartItem) => {
    if (!item.course || !item.course.id) {
      console.error('Item course ID is undefined', item);
      return;
    }

    const updatedItems = items ? [...items, item] : [item];
    const cartId = authState?.user?.cart?.id;

    if (authState?.user?.email && authState?.password && cartId) {
      try {
        await fetchToken(authState.user.email, authState.password);
        const payload = {
          id: cartId,
          discountCode: null,
          items: updatedItems.map((cartItem) => ({
            course: { id: cartItem.course.id },
          })),
        };

        const response = await AXIOS.patch(
          `/api/carts/carts/${cartId}`,
          payload
        );

        setItems(response.data.items || []);
        localStorage.setItem(
          'cartItems',
          JSON.stringify(response.data.items || [])
        );
      } catch (error) {
        console.error('Error adding item to cart:', error);
      }
    }
  };

  const removeFromCart = async (itemId: number) => {
    if (!items) return;

    const updatedItems = items.filter(
      (cartItem) => cartItem.course.id !== itemId
    );
    const cartId = authState?.user?.cart?.id;

    if (authState?.user?.email && authState?.password && cartId) {
      try {
        await fetchToken(authState.user.email, authState.password);
        const payload = {
          id: cartId,
          discountCode: null,
          items: updatedItems.map((cartItem) => ({
            course: { id: cartItem.course.id },
          })),
        };

        const response = await AXIOS.patch(
          `/api/carts/carts/${cartId}`,
          payload
        );

        setItems(response.data.items || []);
        localStorage.setItem(
          'cartItems',
          JSON.stringify(response.data.items || [])
        );
      } catch (error) {
        console.error('Error removing item from cart:', error);
      }
    }
  };

  const applyDiscountCode = async (code: string) => {
    setErrorMessage(null);
    try {
      await updateCart(items || [], code);
    } catch (error) {
      console.error('Error applying discount code:', error);
    }
  };

  const removeDiscountCode = async () => {
    try {
      await updateCart(items || [], null);
    } catch (error) {
      console.error('Error removing discount code:', error);
    }
  };

  const totalRegularPrice =
    items?.reduce((total, item) => total + parseFloat(item.priceRegular), 0) ||
    0;

  const totalSavings =
    items?.reduce(
      (total, item) => total + parseFloat(item.discountAmount),
      0
    ) || 0;

  const totalPrice =
    items?.reduce((total, item) => total + parseFloat(item.priceFinal), 0) || 0;

  return (
    <CartContext.Provider
      value={{
        items,
        setItems,
        itemCount: items?.length || 0,
        addToCart,
        removeFromCart,
        applyDiscountCode,
        removeDiscountCode,
        discountCode,
        errorMessage,
        fetchCart,
        totalRegularPrice,
        totalSavings,
        totalPrice,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
