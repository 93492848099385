import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';

import { AccordionProp } from '@/types/types';

export default function AccordionComponent({ title, content }: AccordionProp) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='border-b border-gray-200'>
      <div
        className='flex justify-between items-center mx-2 cursor-pointer'
        onClick={() => setIsOpen(!isOpen)}
      >
        <h4 className='text-zinc-700 font-semibold'>{title}</h4>
        <span
          className={`transform transition-transform ${
            isOpen ? 'rotate-180' : ''
          }`}
        >
          <ExpandMoreIcon/>
        </span>
      </div>
      {isOpen && <div>{content}</div>}
    </div>
  );
}
