import { useState, useCallback, useEffect } from 'react';
import { Typography, Button } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link, useNavigate } from 'react-router-dom';

import { Cart, CartItem } from '@/types/types';
import AXIOS from '@/util/axios';
import { useAuth } from '@/hooks/useAuth';
import { useCart } from '@/hooks/useCart';
import StepperComponent from '@/components/StepperComponent';

export default function CartPage() {
  const {
    items,
    setItems,
    itemCount,
    removeFromCart,
    applyDiscountCode,
    removeDiscountCode,
    errorMessage,
  } = useCart();

  const [promoCode, setPromoCode] = useState<string>('');
  const [isPromoApplied, setIsPromoApplied] = useState<boolean>(false);
  const [cart, setCart] = useState<Cart | null>(null);
  const { authState } = useAuth();
  const navigate = useNavigate();

  const fetchCart = useCallback(async () => {
    const cartId = authState?.user?.cart?.id;
    if (authState?.token && cartId) {
      try {
        const response = await AXIOS.get(`/api/carts/carts/${cartId}`, {
          headers: {
            Authorization: `Bearer ${authState.token}`,
            'Content-Type': 'application/json',
          },
        });
        const fetchedCart = response.data;
        setCart(fetchedCart);

        if (fetchedCart?.items) {
          setItems(fetchedCart.items);
        }

        if (fetchedCart.discountCode) {
          setPromoCode(fetchedCart.discountCode.id);
          setIsPromoApplied(true);
        } else {
          setIsPromoApplied(false);
        }
      } catch (error) {
        console.error('Error fetching cart:', error);
        setCart(null);
      }
    } else {
      console.error('No token or cart ID available');
    }
  }, [authState, setItems]);

  useEffect(() => {
    if (!authState?.token) {
      navigate('/user-actions/login');
    } else {
      fetchCart();
    }
  }, [authState, fetchCart, navigate]);

  const handleRemoveFromCart = async (item: CartItem) => {
    await removeFromCart(item.course.id);
    await fetchCart();
  };

  const handleApplyPromoCode = async () => {
    if (isPromoApplied) {
      try {
        await removeDiscountCode();
        setPromoCode('');
        setIsPromoApplied(false);
        await fetchCart();
      } catch (error) {
        console.error('Error removing discount code:', error);
      }
    } else {
      try {
        await applyDiscountCode(promoCode);
        setIsPromoApplied(true);
        await fetchCart();
      } catch (error) {
        console.log('Error applying discount code:', error);
      }
    }
  };

  const totalPrice = cart?.priceFinalTotal || 0;
  const totalRegularPrice = cart?.priceRegularTotal || 0;
  const totalSavings = cart?.discountAmountTotal || 0;

  return (
    <main className='bg-white flex flex-grow flex-col sm:flex-row overflow-hidden'>
      <section className='flex flex-col w-full h-full mx-auto'>
        <div className='m-10 md:m-20 sm:m-20 h-full'>
          <section className='w-5/6 mx-auto'>
            <StepperComponent activeStep={0} />
          </section>
          <h1 className='text-3xl font-semibold'>Koszyk</h1>

          {itemCount === 0 ? (
            <h2>Twój koszyk jest pusty</h2>
          ) : (
            <div className='grid grid-cols-1 gap-4'>
              {items?.map((item: CartItem, index: number) => (
                <div key={item.course.id || index}>
                  <div className='flex items-center justify-between p-4'>
                    <img
                      src={item.course.thumbnailUrl}
                      alt={item.course.name}
                      className='w-1/5 rounded-lg object-cover mr-4'
                    />
                    <div className='flex-1'>
                      <Typography variant='h6'>{item.course.name}</Typography>
                    </div>
                    <div className='flex flex-col items-end justify-center'>
                      <Typography
                        variant='body1'
                        color='textSecondary'
                        style={{
                          color: '#f56c42',
                          fontWeight: 'bolder',
                        }}
                      >
                        {item.priceFinal}
                      </Typography>

                      {parseFloat(item.discountAmount) > 0 && (
                        <Typography
                          variant='body2'
                          color='textSecondary'
                          style={{
                            textDecoration: 'line-through',
                          }}
                        >
                          {item.priceRegular}
                        </Typography>
                      )}

                      <Button
                        color='inherit'
                        startIcon={<DeleteIcon />}
                        className='bg-zinc-600 text-white hover:bg-zinc-700'
                        onClick={() => handleRemoveFromCart(item)}
                      >
                        usuń
                      </Button>
                    </div>
                  </div>
                  <hr style={{ border: '1px solid #f56c42', width: '100%' }} />
                </div>
              ))}
            </div>
          )}
        </div>
      </section>

      <div className='flex flex-col w-full sm:w-1/3 bg-gray-100 justify-center items-center p-4'>
        <div className='flex flex-col justify-between items-center m-12 w-full'>
          <div className='flex flex-col items-center justify-center mb-4 w-full'>
            <div className='w-full'>
              <div className='flex justify-between w-full'>
                <Typography variant='h6' style={{ padding: '0.5em' }}>
                  Cena regularna:
                </Typography>
                <Typography
                  variant='h6'
                  style={{
                    padding: '0.5em',
                    color: '#5c5e5d',
                    fontWeight: 'bolder',
                  }}
                >
                  {totalRegularPrice}
                </Typography>
              </div>
              <div className='flex justify-between w-full'>
                <Typography variant='h6' style={{ padding: '0.5em' }}>
                  Rabat:
                </Typography>
                <Typography
                  variant='h6'
                  style={{
                    padding: '0.5em',
                    color: '#5c5e5d',
                    fontWeight: 'bolder',
                  }}
                >
                  {totalSavings}
                </Typography>
              </div>

              <hr style={{ border: '1px solid #f56c42', width: '100%' }} />
              <div className='flex justify-between w-full'>
                <Typography variant='h6' style={{ padding: '0.5em' }}>
                  Kwota łączna:
                  <p className='text-xs'>z VAT</p>
                </Typography>

                <Typography
                  variant='h6'
                  style={{
                    padding: '0.5em',
                    color: '#5c5e5d',
                    fontWeight: 'bolder',
                  }}
                >
                  {totalPrice}
                </Typography>
              </div>

              <div className='flex mx-10 mt-10 items-center'>
                <div className='flex flex-col w-full'>
                  <input
                    type='text'
                    placeholder='Wpisz kod rabatowy'
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    className='mb-2 border-b border-gray-400 bg-transparent text-gray-700 text-sm placeholder-gray-500 focus:outline-none focus:ring-0'
                  />
                </div>
                <Button
                  onClick={handleApplyPromoCode}
                  style={{
                    backgroundColor: 'transparent',
                    border: '1px solid #bbbdbc',
                    color: '#4A5568',
                    padding: '3px 6px',
                    transition: 'background-color 0.3s',
                    margin: '0em 2em',
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = '#dbdbdb')
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = 'transparent')
                  }
                >
                  {isPromoApplied ? 'usuń' : 'dodaj'}
                </Button>
              </div>

              <div className='mx-10'>
                {errorMessage && (
                  <Typography color='error' variant='caption'>
                    {errorMessage}
                  </Typography>
                )}
              </div>
            </div>

            <div className='flex justify-end items-center my-8 w-full'>
              <Button
                variant='contained'
                component={Link}
                to='/cart/checkout'
                color='warning'
                className='bg-orange-500 text-white hover:bg-orange-600 w-full'
              >
                Przejdź dalej
              </Button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
